<!-- components/ImageViewer.vue -->
<template>
    <div class="btncontainer">
        <!-- <button @click="backPage">关闭</button>
        <button v-print="'#print-content'">打印</button> -->
        <select v-model="num" @change="numChange">
            <option v-for="option in numlist" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <el-button @click="backPage" type="primary">关闭</el-button>
        <el-button v-print="'#print-content'" type="success">打印</el-button>
        <!-- <button @click="test">打印</button> -->
    </div>
    <div class="image-viewer" id="print-content">
        <!-- <div class="modelcomtainer"> -->
        <div>
            <div class="modelcomtainer" v-for="(Item, index) in comprehensive_scorelist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">单元成绩表</span>
                    </div>
                    <div class="unitordertipscontainer">
                        <div class="sessionnumcontainer">
                            <span class="sessionnumfontstyle">{{ sessionNum }}</span>
                        </div>
                        <div class="unitordertipcontainer">
                            <!-- <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">时间</span>
                            </el-col> -->
                            <el-col :span="2" class="tipsstyle">
                                <span class="tipsfontstyle">场次号</span>
                            </el-col>
                            <el-col :span="7" class="tipsstyle">
                                <span class="tipsfontstyle">青方</span>
                            </el-col>
                            <el-col :span="7" class="tipsstyle">
                                <span class="tipsfontstyle">红方</span>
                            </el-col>
                            <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">比分</span>
                            </el-col>
                            <el-col :span="2" class="tipsstyle">
                                <span class="tipsfontstyle">获胜方式</span>
                            </el-col>
                            <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">获胜方</span>
                            </el-col>
                        </div>
                    </div>
                    <div class="unitorderdetailcontainer" v-for="(item) in Item" :key="item.id">
                        <!-- <el-col :span="3">
                            <el-row style="height: 50%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">9:00</span>
                            </el-row>
                        </el-col> -->
                        <el-col :span="2">
                            <el-row style="height: 50%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{ item.fieldName }}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="7">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.groupAName }}</span> </el-col>
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.teamAName }}</span>
                                </el-col>
                            </el-row>
                        </el-col>
                        <!-- <el-col :span="2">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>---</span> </el-col>
                            </el-row>
                        </el-col> -->
                        <el-col :span="7">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.groupBName }}</span> </el-col>
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.teamBName }}</span>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="3">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{ item.scoreA }} ： {{ item.scoreB }}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="2">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{item.winWay}}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="3">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <!-- <span style="font-size: small;"> -->
                                    <span style="font-size: 20px;" v-if="item.winner == null"></span>
                                    <span style="font-size: 20px;" v-else-if="item.winner == 'A'">青方</span>
                                    <span style="font-size: 20px;" v-else>红方</span>
                                    
                                    <!-- {{ item.winner == null }} -->
                                <!-- </span> -->
                            </el-row>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ comprehensive_scorelist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            // TemplatePrintingActive: false,
            // modelimageSrc: '',
            comprehensive_scorelist: [
                // [
                //     { name: "张三", id: 1, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 2, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 3, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 4, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 5, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 6, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 7, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 8, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 9, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 10, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 11, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 12, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 13, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 14, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 15, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                // ],
                // [
                //     { name: "张三", id: 1, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 2, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 3, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 4, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 5, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 6, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 7, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 8, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 9, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "张三", id: 10, unit: "鬼手道场", seed: 1, BibNumber: 101, brithday: '2000-09-12' },
                //     { name: "李四", id: 11, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' },
                //     { name: "王五", id: 12, unit: "鬼手道场", seed: 2, BibNumber: 102, brithday: '2000-09-12' }
                // ]
            ],//单元秩序表数据列表
            sessionNum: '',
            matchitenlevel: '',
            matchitemname: '',
            // matchdata: '',
            matchname: '',
            matchitemtype: '',
            matchgrouptype: '',
            // imageSrc: '',
            num: 14,
            modeltype: '',
            numlist: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 },
                { label: 5, value: 5 },
                { label: 6, value: 6 },
                { label: 7, value: 7 },
                { label: 8, value: 8 },
                { label: 9, value: 9 },
                { label: 10, value: 10 },
                { label: 11, value: 11 },
                { label: 12, value: 12 },
                { label: 13, value: 13 },
                { label: 14, value: 14 },
                { label: 15, value: 15 },
                { label: 16, value: 16 },
                { label: 17, value: 17 },
                { label: 18, value: 18 },
                { label: 19, value: 19 },
                { label: 20, value: 20 },
            ],
        }
    },
    created() {
        console.log(this.$route.query)
        this.sessionNum = this.$route.query.sessionName
        this.modeltype = this.$route.query.type
        this.upgradedata(this.modeltype)
    },
    methods: {
        numChange(e) {
            this.upgradedata(this.modeltype)
            console.log(e);
        },
        upgradedata(value) {
            var that = this
            switch (value) {
                case "normal":
                    axios({
                        url: this.$globalurl + 'report/getGrade',
                        method: 'get',
                        params: {
                            gameId: this.$route.query.gameId,
                            sessionId: this.$route.query.sessionId,
                            fieldId: this.$route.query.selectLocationId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response);
                        let currentlist = []
                        let a = 0
                        that.comprehensive_scorelist = []
                        that.matchname = response.data.gameName
                        // that.matchitemname = response.data.comItem.name
                        // that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        // that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.gradeList.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.gradeList[i])
                                    that.comprehensive_scorelist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.gradeList[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.comprehensive_scorelist.push(currentlist)
                            }
                        }
                        console.log(that.comprehensive_scorelist)
                    })
                    break
                case "flexible":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getGrade',
                        method: 'get',
                        params: {
                            gameId: this.$route.query.gameId,
                            fieldId: this.$route.query.selectLocationId,
                            fnoBegin: this.$route.query.fnoBegin,
                            fnoEnd: this.$route.query.fnoEnd
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response);
                        let currentlist = []
                        let a = 0
                        that.comprehensive_scorelist = []
                        that.matchname = response.data.gameName
                        // that.matchitemname = response.data.comItem.name
                        // that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        // that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.gradeList.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.gradeList[i])
                                    that.comprehensive_scorelist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.gradeList[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.comprehensive_scorelist.push(currentlist)
                            }
                        }
                        console.log(that.comprehensive_scorelist)
                    })
                    break
                default:
                    //这里是没有找到对应的值处理
                    break
            }
        }
    }
    // mounted() {
    //     // 组件挂载后，等图片加载完成再缩放
    //     this.scaleImage();
    // },
};
</script>

<style scoped>
.signaturecontainer {
    height: 60%;
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: column;
}

.althlesignaturecontainer {
    height: 5%;
    display: flex;
}

.weighttotalcontainer {
    border: 1px solid black;
    height: 15%;
    width: 98%;
    margin-left: 1%;
}

.officialsignaturecontainer {
    height: 15%;
    width: 98%;
    margin-left: 1%;
}

/* .reportformtipscontainer {
    height: 5%;
    width: 98%;
    margin-left: 1%;
} */

.tipsfontstyle {
    font-weight: bold;
}

.tipsstyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-divider--horizontal {
    margin-top: 8px;
    margin-bottom: 2px;
    background: 0 0;
    border-top: 1px solid black;
}

/* .matchimgcontainer {
    max-width: 100%;
    max-height: 100%;
} */

.toptitlecontainer {
    height: 8%;
    width: 100%;
}

.matchrighttitletwofontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchrighttitletherefontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchrighttitletwocontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
}

.matchrighttitletherecontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.matchrighttitleonefontstyle {
    font-size: large;
    font-weight: bold;
}

.matchrighttitleonecontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
}

.matchlefttitletwofontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchlefttitletherefontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchlefttitletwocontainer {
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-left: 5%;
}

.matchlefttitletherecontainer {
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-left: 5%;
    padding-bottom: 5%;
}

.matchlefttitleonefontstyle {
    font-size: larger;
    font-weight: bold;
}

.matchlefttitleonecontainer {
    text-align: center;
    height: 26%;
    padding-top: 5%;
    padding-left: 5%;
}

.modelcomtainer {
    /* width: 612pt;
    height: 784pt; */
    width: 210mm;
    height: 297mm;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid #ddd; */
    background-color: white;
}

.image-viewer {
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgb(246, 246, 246);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 根据需要添加内边距 */

}

.image-viewer img {
    max-width: 100%;
    max-height: 100%;
}

.contentcontainer {
    height: 85%;
    width: 100%;
}

.titlecontainer {
    /* padding: 2%; */
    height: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weighttipscontainer {
    width: 98%;
    margin-left: 1%;
    height: 5%;
    display: flex;
    border: 1px solid black;
}

.titlefontstyle {
    font-size: large;
    font-weight: bold;
}

.tipscontainer {
    display: flex;
    height: 5%;
    width: 98%;
    margin-left: 1%;
    border: 1px solid black;
}

.unitordertipscontainer {
    display: flex;
    flex-direction: column;
    height: 8%;
    margin-left: 1%;
    width: 98%;
    border: 1px solid black;
}

.sessionnumcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    /* width: 100%; */
    height: 39%;
}

.sessionnumfontstyle {
    font-size: large;
    font-weight: bold;
}

.unitordertipcontainer {
    display: flex;
    height: 60%;
}

.unitorderdetailcontainer {
    /* height: 5%; */
    /* padding-top: 5px; */
    margin-left: 1%;
    width: 98%;
    margin-top: 2%;
    margin-bottom: 3%;
    display: flex;
}

.contentdetailcontainer {
    height: 90%;
    width: 98%;
    margin-left: 1%;
}

.detailmsgcontainer {
    display: flex;
    width: 98%;
    margin-left: 1%;
    margin-top: 2%;
    margin-bottom: 3%;
}

.btncontainer {
    position: fixed;
    /* 固定定位 */
    top: 20px;
    /* 距离顶部20像素 */
    right: 20px;
    /* 距离右侧20像素 */
    z-index: 1000;
    /* 确保按钮在页面其他内容的上方 */
}

.el-row {
    margin-bottom: 0px;
}

.el-col {
    margin-bottom: 0px;
}

/* 定义打印样式，隐藏按钮 */
/* @media print {
    .btncontainer {
        display: none;
    }
} */
</style>