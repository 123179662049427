<template>

    <div id="main-container" class="container">
        <el-container>
            <!-- 头 -->
            <header class="headerCom">
                <h1>{{this.comName }}&nbsp;|&nbsp;{{   this.itemName }}</h1>
                <div class="back-button">
                    <!-- <el-button  link type="primary" @click="switchMatches()">切换比赛</el-button> -->
                    <el-button @click="goBack" >主页</el-button>
                    <el-button link>{{ this.userType }}</el-button>
                    <el-button link type="danger" @click="seleceAcount">切换账号</el-button>
                </div>   
            </header>
        </el-container>
        <el-container>
            <aside class="sidebar">
                <el-menu style="height: 100vh;" :router="true" :default-active=menuActive>
                    <el-menu-item index="/ToConfigurationCompetitionPage"
                        @click="addTab('级别管理', 'ToConfigurationCompetitionPage')">
                        <el-icon>
                            <Location />
                        </el-icon>
                        <span>级别管理</span>
                    </el-menu-item>
                    <!-- 比赛管理 -->
                    <!--  -->
                    <el-menu-item index="/ToLotteryPage" @click="addTab('抽签管理', 'ToLotteryPage')">
                        <el-icon>
                            <Edit />
                        </el-icon>
                        <span>抽签管理</span>
                    </el-menu-item>
                    <el-menu-item index='/ToOpponentChartInformationPage' @click="addTab('编排管理', 'ToOpponentChartInformationPage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>编排管理</span>
                    </el-menu-item>
                    <el-menu-item index='/ToDelegationSignUpCheckPage'
                        @click="addTab('报名审核(代表队)', 'ToDelegationSignUpCheckPage')">
                        <el-icon><User /></el-icon>
                        <span>报名审核(代表队)</span>
                    </el-menu-item>
                    <el-menu-item index='/ToDelegationCheckPage'
                        @click="addTab('入队审核', 'ToDelegationCheckPage')">
                        <el-icon><User /></el-icon>
                        <span>入队审核</span>
                    </el-menu-item>
                    <el-menu-item index='/ToDelegationManagementPage'
                        @click="addTab('代表队管理', 'ToDelegationManagementPage')">
                        <el-icon><User /></el-icon>
                        <span>代表队管理</span>
                        
                    </el-menu-item>
                    <el-menu-item index='/ToDelegationPage'
                        @click="addTab('代表队', 'ToDelegationPage')">
                        <el-icon><User /></el-icon>
                        <span>代表队</span>
                    </el-menu-item>
                    <el-menu-item index='/ToCheckPage' @click="addTab('报名审核(组委会)', 'ToCheckPage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>报名审核(组委会)</span>
                    </el-menu-item>
                    <el-menu-item index='/ToLocationManager' @click="addTab('项目设置', 'ToLocationManager')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>项目设置</span>
                    </el-menu-item>
                    <el-menu-item index='/ToCheckManagementPage' @click="addTab('检录管理', 'ToCheckManagementPage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>检录管理</span>
                    </el-menu-item>
                    <el-menu-item index='/ToWeightManagementPage' @click="addTab('称重管理', 'ToWeightManagementPage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>称重管理</span>
                    </el-menu-item>
                    <el-menu-item index='/ToAccountManagementPage' @click="addTab('账号管理', 'ToAccountManagementPage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>账号管理</span>
                    </el-menu-item>
                    <el-menu-item index='/ToTeamLeaderRegistrationPage' @click="addTab('领队报名', 'ToAccountMToTeamLeaderRegistrationPageanagementPage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>领队报名</span>
                    </el-menu-item>
                    <el-menu-item index='/ToPrintMangePage' @click="addTab('打印管理', 'ToPrintMangePage')">
                        <el-icon>
                            <User />
                        </el-icon>
                        <span>打印管理</span>
                    </el-menu-item>

                    
                </el-menu>
            </aside>
            <main class="content">
                <el-tabs style="margin-left: 10px;" v-model="editableTabsValue" type="card" closable
                    @tab-remove="removeTab" @tab-click="handleTabClick">
                    <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
                    </el-tab-pane>
                </el-tabs>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </main>
        </el-container>
    </div>
</template>

<script>
  export default {
    name: 'MainLayout',
    data(){
        return {
        editableTabsValue: '0',
        editableTabs: [],
        tabIndex: 0,
        itemName:'',
        comName:'',
        menuActive:'/ToConfigurationCompetitionPage',
        userType:''
        }
    },
    created(){
        this.comName = localStorage.getItem("comName")
        this.itemName = localStorage.getItem("itemName")
        this.userType=localStorage.getItem("userType")
        this.$router.push({
        path:'/ToConfigurationCompetitionPage',
        query:{itemName:this.$route.query.itemName,comId:this.$route.query.comId}
      })
    },
    methods:{
        seleceAcount(){
            this.$router.push("/")
        },
        goBack() {
            this.$router.push("/ToHomePage")
        },
        // 移除标签的方法
        removeTab(targetName) {
     
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        let flag = 0
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              flag = index
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        if(this.editableTabs.length==0){
            this.$router.push('/ToConfigurationCompetitionPage');
            this.menuActive = "/"+"ToConfigurationCompetitionPage"
            return
        }
        if(this.editableTabs[flag]==null){
            flag--
        }
        this.menuActive = "/"+this.editableTabs[flag].name
        this.$router.push('/'+this.editableTabs[flag].name);
      },
      // 添加标签的方法
      addTab(targetName,url) {
        for(let i =0 ; i<this.editableTabs.length;i++){
            if(this.editableTabs[i].title==targetName){
                this.editableTabsValue = url;
                return
            }
        }
        this.editableTabs.push({
          title: targetName,
          name: url
        });
        this.editableTabsValue = url;
      },
      handleTabClick(tab) {
        this.menuActive = "/"+tab.props.name
        this.$router.push('/'+ tab.props.name);
    },
    switchMatches(){
        this.$router.push("/ToSelectItemPage")
    }
    }
  };

  </script>

<style scoped>
body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

.container {
    height: 100%;
}

.headerCom {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    /* 根据需要调整内边距 */
}

.headerCom h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.sidebar {
    background: #f8f9fa;
    width: 160px;
    /* padding: 2rem; */
    padding-top: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.content {
    height: 100%;
    width: 100%;
    padding-top: 1rem;
}


.back-button {
    margin-left: auto;
    /* 将按钮放置在最右边 */
}
</style>