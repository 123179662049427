<template>
  <el-card>
    <el-row>
      <el-button link @click="diaPrintSession = true" type="primary">打印单元秩序单</el-button>
      <el-button link @click="sorcePrintSession = true" type="primary">打印单元成绩单</el-button>
      <el-button link @click="diaPrintflexibleSession = true" type="primary">打印灵活秩序单</el-button>
      <el-button link @click="sorcePrintflexibleSession = true" type="primary">打印灵活成绩单</el-button>
    </el-row>
  </el-card>

  <!-- 导入KPNP信息 -->
  <el-dialog v-model="sorcePrintflexibleSession" style="width: 400px;">
    <el-form-item label="场地" style="margin-bottom: 10px" label-width="120">
      <el-select v-model="selectLocationId" placeholder="选择场地" style="width: 200px">
        <el-option v-for="location in LocationList" :key="location.id" :label="location.name" :value="location.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="起始场号" style="margin-bottom: 10px" label-width="120">
      <el-input type="text" placeholder="请输入场号" v-model="fnoBegin">
      </el-input>
    </el-form-item>
    <el-form-item label="最终场号" style="margin-bottom: 10px" label-width="120">
      <el-input type="text" placeholder="请输入场号" v-model="fnoEnd">
      </el-input>
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="sorcePrintflexibleSession = false">取消</el-button>
        <el-button type="primary" @click="sorceflexiblePrint()"> 确定打印 </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="diaPrintflexibleSession" style="width: 400px;">
    <el-form-item label="场地" style="margin-bottom: 10px" label-width="120">
      <el-select v-model="selectLocationId" placeholder="选择场地" style="width: 200px">
        <el-option v-for="location in LocationList" :key="location.id" :label="location.name" :value="location.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="起始场号" style="margin-bottom: 10px" label-width="120">
      <el-input type="text" placeholder="请输入场号" v-model="fnoBegin">
      </el-input>
    </el-form-item>
    <el-form-item label="最终场号" style="margin-bottom: 10px" label-width="120">
      <el-input type="text" placeholder="请输入场号" v-model="fnoEnd">
      </el-input>
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaPrintflexibleSession = false">取消</el-button>
        <el-button type="primary" @click="submitflexiblePrint()"> 确定打印 </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="diaPrintSession" style="width: 400px;">
    <el-form-item label="场地" style="margin-bottom: 10px" label-width="120">
      <el-select v-model="selectLocationId" placeholder="选择场地" style="width: 200px">
        <el-option v-for="location in LocationList" :key="location.id" :label="location.name" :value="location.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="单元" style="margin-bottom: 10px" label-width="120">
      <el-select v-model="selectSessionId" placeholder="选择场地" style="width: 200px">
        <el-option v-for="session in sessionList" :key="session.id" :label="session.sessionNum" :value="session.id" />
      </el-select>
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaPrintSession = false">取消</el-button>
        <el-button type="primary" @click="submitPrint()"> 确定打印 </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="sorcePrintSession" style="width: 400px;">
    <el-form-item label="场地" style="margin-bottom: 10px" label-width="120">
      <el-select v-model="selectLocationId" placeholder="选择场地" style="width: 200px">
        <el-option v-for="location in LocationList" :key="location.id" :label="location.name" :value="location.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="单元" style="margin-bottom: 10px" label-width="120">
      <el-select v-model="selectSessionId" placeholder="选择场地" style="width: 200px">
        <el-option v-for="session in sessionList" :key="session.id" :label="session.sessionNum" :value="session.id" />
      </el-select>
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="sorcePrintSession = false">取消</el-button>
        <el-button type="primary" @click="sorcePrint()"> 确定打印 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      sorcePrintflexibleSession: false,
      diaPrintflexibleSession: false,
      diaPrintSession: false,
      sorcePrintSession: false,
      LocationList: [],
      sessionList: [],
      selectSessionId: '',
      selectLocationId: '',
      fnoBegin:'',
      fnoEnd:''
    }
  },
  created() {
    this.getAllLocation()
    this.getAllSession()
  },
  methods: {
    // 获取所有场地
    getAllLocation() {
      var that = this;
      axios({
        url: this.$globalurl + "field/getByGameAndItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.LocationList = response.data;
      });
    },
    // 获取所有单元
    getAllSession() {
      var that = this;
      axios({
        url: this.$globalurl + "session/getByGameIdAndItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.sessionList = response.data;
        console.log(response.data);
      });
    },
    submitPrint() {
      console.log(this.selectLocationId);
      console.log(this.selectSessionId);
      console.log(this.sessionList);
      var sessionOject = this.sessionList.filter(item =>item.id===this.selectSessionId)
    
      let routeUrl = this.$router.resolve({
        path: "/ToUnitordertablePage",
        query: { gameId: this.sessionList[0].gameId, sessionId: this.selectSessionId, sessionName: sessionOject[0].sessionNum, selectLocationId: this.selectLocationId ,type: 'normal'},
      });
      window.open(routeUrl.href, "_blank");
    },
    sorcePrint() {
      console.log(this.selectLocationId);
      console.log(this.selectSessionId);
      console.log(this.sessionList);
      var sessionOject = this.sessionList.filter(item =>item.id===this.selectSessionId)
   
      let routeUrl = this.$router.resolve({
        path: "/ToUnitscoretablePage",
        query: { gameId: this.sessionList[0].gameId, sessionId: this.selectSessionId,sessionName: sessionOject[0].sessionNum, selectLocationId: this.selectLocationId ,type: 'normal'},
      });
      window.open(routeUrl.href, "_blank");
    },
    submitflexiblePrint(){
      var sessionOject = this.sessionList.filter(item =>item.id===this.selectSessionId)
      let routeUrl = this.$router.resolve({
        path: "/ToUnitordertablePage",
        query: { fnoBegin: this.fnoBegin,fnoEnd: this.fnoEnd, gameId: this.sessionList[0].gameId, sessionId:this.selectSessionId,sessionName: sessionOject[0].sessionNum, selectLocationId: this.selectLocationId ,type: 'flexible'},
      });
      window.open(routeUrl.href, "_blank");
    },
    sorceflexiblePrint(){
      var sessionOject = this.sessionList.filter(item =>item.id===this.selectSessionId)
      let routeUrl = this.$router.resolve({
        path: "/ToUnitscoretablePage",
        query: { fnoBegin: this.fnoBegin,fnoEnd: this.fnoEnd, gameId: this.sessionList[0].gameId, sessionId: this.selectSessionId,sessionName: sessionOject[0].sessionNum, selectLocationId: this.selectLocationId ,type: 'flexible'},
      });
      window.open(routeUrl.href, "_blank");
    }
  }
}
</script>

<style></style>