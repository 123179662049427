<template>
  <div id="background">
    <div id="logo">REGISTRE</div>
    <div>
      <el-card id="registration" class="box-card">
        <!-- 返回按钮 -->
         <!-- 返回按钮 -->
      <el-button class="back-button" @click="goBack">返回</el-button>
        <el-form style="margin-top: 30px;" id="form" :model="formUser" :rules="rules" ref="registerForm" label-width="100px">
          <el-form-item label="手机号 :">
            <el-input v-model="formUser.phone" placeholder="请输入您的手机号" style="width: 190px;"></el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item label="验证码 :">
            <div class="captcha-container">
              <el-input v-model="inputCaptcha" placeholder="输入验证码" style="width: 100px; margin-right: 10px;"></el-input>
              <canvas ref="captchaCanvas" @click="generateCaptcha" width="80" height="30"></canvas>
            </div>
          </el-form-item>
          <el-form-item label="密码 :">
            <el-input type="password" v-model="formUser.password" placeholder="请输入密码" style="width: 190px;"
              show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码 :">
            <el-input type="password" v-model="formUser.confirmPassword" placeholder="请输入密码" style="width: 190px;"
              show-password></el-input>
          </el-form-item>
          <el-form-item label="角色 :">
            <el-select v-model="formUser.identity" placeholder="请选择角色" style="width: 190px;">
              <el-option v-for="item in identityList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm(formUser)">确定注册</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div id="clock">
      <div id="time">{{ time }}</div>
      <div id="date">{{ date }}</div>
    </div>
    <div id="copyright">
      copyright @ {{ year }} 拳跆空(上海)体育经济发展有限公司. All Rights Reserved {{ score }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';

  export default {
    created(){
      window.addEventListener("storage",e=>{
        if(e.key =="msg"){
            console.log(e.newValue);
        }
      })
    },
    data() {
      return {
        labelPosition: "right",  // 让用户名 ，密码，角色 像右对其
        formUser:{
          phone:"",// 用户名 或者手机号
          password:"",
          identity: "", // 用户的身份
          confirmPassword:"", //确认密码
        },
        identityList: [ // 用户身份的可选项
          { value: "运动员", label: "运动员" },
            { value: "领队", label: "领队" },
            { value: "组委会", label: "组委会" },
            { value: "管理员", label: "管理员" },
        ],
        time: "", // 显示的时间 
        date: "", // 日期
        year: "", // 年份
        score:0,
        captcha: '', // 验证码
        inputCaptcha: '', // 输入的验证码
      };
    },
    mounted() {
      this.$nextTick(() => { // 定时器方法 
        setInterval(this.update_clock, 100);
      });

      this.generateCaptcha(); // 组件挂载时生成验证码
    },
    methods: {
    update_clock: function () {
        var d = new Date();
        var year = d.getFullYear();
        if (year < 10) {
          year = "0" + year;
        }
        var mon = d.getMonth()+1;
        if (mon < 10) {
          mon = "0" + mon;
        }
        var day = d.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        var hour = d.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        var minute = d.getMinutes();
        if (minute < 10) {
          minute = "0" + minute;
        }
        this.time = hour + ":" + minute;
        this.date = year + "/" + mon + "/" + day;
        this.year = year;
      },
    registrationEvent(){
        // 跳转到注册页面
        this.$router.push('/ToRegistrationPage');
      },
      // 绘制验证码
    generateCaptcha() {
      const canvas = this.$refs.captchaCanvas;
      const ctx = canvas.getContext('2d');
      const captchaLength = 4; // 验证码长度
      let captchaCode = '';

      // 绘制背景
      ctx.fillStyle = '#f0f0f0';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 绘制干扰线
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.getRandomColor();
        ctx.beginPath();
        ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.stroke();
      }

      // 绘制验证码
      for (let i = 0; i < captchaLength; i++) {
        const fontSize = Math.floor(15 + Math.random() * 5);
        const char = this.getRandomChar();
        captchaCode += char;
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = this.getRandomColor();
        ctx.fillText(char, 17 * i + 10, 24);
      }

      this.captchaCode = captchaCode; // 保存验证码用于验证
    },
    getRandomColor() {
      const rgb = [];
      for (let i = 0; i < 3; i++) {
        rgb.push(Math.floor(Math.random() * 256));
      }
      return `rgb(${rgb.join(',')})`;
    },
    getRandomChar() {
      const chars = '0123456789';
      return chars.charAt(Math.floor(Math.random() * chars.length));
    },
    // 判断是否是手机号
    isMobilePhone(phoneNumber) {
      const mobileRegex = /^1[3-9]\d{9}$/;
      return mobileRegex.test(phoneNumber);
    },
    // 提交注册
    submitForm(formUser) {
      console.log(formUser);
      var that = this
      axios.post(that.$globalurl+'account/enroll',{
        password:formUser.password,
        phone:formUser.phone,
        type:formUser.identity
      }).then(function (response){
        console.log(response);
      })


      if(this.isMobilePhone(formUser.phone)!=true){
        this.$message({
              type:'error',
              message:'请输入正确的手机号'
            })
            return 
      }
      if(formUser.password == '' || formUser.confirmPassword==''){
        this.$message({
              type:'error',
              message:'密码不能为空'
            })
            return 
      }
      if (this.inputCaptcha.toUpperCase() === this.captchaCode) {
        // 判断两次密码是否一致
          if(formUser.confirmPassword != formUser.password){
            this.$message({
              type:'error',
              message:'两次密码不一致'
            })
            return 
          }
          // 判断是否填写 角色
          if(formUser.identity ==''){
            this.$message({
              type:'error',
              message:'请选择角色'
            })
            return 
          }
        this.$message({
          type: 'success',
          message: '注册成功'
        });
        this.$router.push({
          path:"/",
          query:{phone:formUser.phone,password:formUser.password}
        })
      } else {
        this.$message({
          type: 'error',
          message: '验证码输入错误'
        });
        this.resetForm()
      }

    },
   // 重置表单的 methods
   resetForm() {
      this.formUser = {}
      this.inputCaptcha = ''
      this.generateCaptcha()
    },
    // 返回按钮
    goBack() {
      // 使用 JavaScript 的 history.back() 返回上一页
      window.history.back();
    },
    },
  };
  </script>

<style scoped>
#background {
  background-color: darkgreen;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(90deg,
      rgb(160, 178, 183),
      rgb(164, 159, 164));
  background-size: 400%;
  animation: myanimation 10s infinite;
}

/* @keyframes myanimation {
    0% {
      background-position: 0% 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0% 50%;
    }
  } */

#logo {
  color: white;
  font-size: 50px;
  font-weight: 800;
  position: absolute;
  top: 10%;
  left: 15%;
}

#registration {
  position: absolute;
  top: 20%;
  left: 40%;
  border: none;
  width: 400px;
}

.el-card {
  width: 350px;
  height: 410px;
  background-color: rgb(255, 255, 255, 0.4);
  box-shadow: 0 10px 26px 0px rgba(52, 81, 91, 0.5);
}

#clock {
  color: aliceblue;
  position: absolute;
  top: 70%;
  left: 15%;
  text-align: center;
}

#time {
  font-size: 100px;
}

#date {
  font-size: 35px;
}

#copyright {
  color: aliceblue;
  position: absolute;
  top: 95%;
  left: 38%;
}

.custom-button {
  float: right;
  margin-right: 10px;
  height: 32px;
  background-color: rgba(160, 178, 183, 0.8);
  /* 初始背景颜色 */
  border: none;
  transition: background-color 0.3s;
  /* 平滑过渡效果 */
}

/* 悬浮时颜色变深 */
.custom-button:hover {
  background-color: rgba(120, 138, 143, 0.8);
  /* 悬浮时的深色背景 */
}

/* 点击时颜色更深，这里使用了更暗的颜色 */
.custom-button:active {
  background-color: rgba(80, 98, 103, 0.8);
  /* 点击时的更深颜色背景 */
}

.captcha-container {
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
}

.back-button {
  position: absolute;
  /* 绝对定位 */
  top: 10px;
  /* 根据实际情况调整 */
  left: 10px;
  /* 根据实际情况调整 */
  background-color: transparent;
  /* 透明背景 */
  color: white;
  /* 白色文字 */
  border: none;
  /* 无边框 */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  /* 添加阴影 */
  cursor: pointer;
  /* 鼠标悬停时显示手形图标 */
  transition: transform 0.2s, box-shadow 0.2s;
  /* 平滑过渡效果 */
  padding: 5px 10px;
  /* 按钮内边距 */
  font-size: 16px;
  /* 字体大小 */
  z-index: 10;
  /* 确保按钮在卡片之上 */
}
.back-button:hover {
  transform: scale(1.1);
  /* 鼠标悬停时放大 */
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.7);
  /* 阴影加深 */
}

.back-button:active {
  transform: scale(0.9);
  /* 点击时缩小 */
}
#registration .el-card {
  transition: box-shadow 0.3s; /* 平滑过渡效果 */
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1); /* 轻微的初始阴影 */
}
#registration .captcha-container canvas {
  cursor: pointer; /* 当鼠标悬浮在canvas上时，光标变成小手 */
}


</style>