<template>
  <el-row>
    <el-button link type="primary" style="margin-left: 20px; margin-top: 20px"
      @click="addCheckInformation()">添加账号</el-button>
    <el-select v-model="accountType" placeholder="选择账号类型"
      style="width: 240px; margin-left: 20px; margin-right: 20px;margin-top: 20px;">
      <el-option v-for="item in accountClassList" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
  </el-row>
  <el-table :data="Classifyaccount" style="width: 100%;margin-left: 50px;">
    <el-table-column prop="id" label="序号" width="100" />
    <el-table-column prop="account" label="账号" width="180" />
    <el-table-column prop="password" label="密码" width="180" />
    <el-table-column prop="place" label="场地" width="180" />
    <el-table-column prop="gender" label="A/B方" width="180" />
    <el-table-column prop="accountType" label="账号类型" width="180" />
    <el-table-column label="操作" width="180">
      <template v-slot="scope">
        <el-button link type="primary" @click="edit(scope.row)">修改密码</el-button>
        <el-button link type="danger" @click="deleteAccount(scope.row)">删除</el-button>
        <el-button link type="primary" @click="bindaccount(scope.row)">绑定</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
    :current-page="page" :page-sizes="[5, 10, 15, 20]" :page-size="size"
    layout="total, sizes, prev, pager, next, jumper" :total="total" style="margin-top: 50px;justify-content: center;">
  </el-pagination>
  <el-dialog v-model="diaCheckShow" title="添加账号" width="500" :before-close="handleClose">
    <el-form label-width="70">
      <el-form-item label="账号&nbsp;&nbsp;">
        <el-input style="width: 200px" v-model="this.account.username" clearable />
      </el-form-item>
      <el-form-item label="密码&nbsp;&nbsp;">
        <el-input style="width: 200px" v-model="this.account.password" clearable type="password" show-password />
      </el-form-item>
      <el-form-item label="地点&nbsp;&nbsp;">
        <el-input style="width: 200px" placeholder="请输入房间号或场地号" v-model="this.account.place" clearable />
      </el-form-item>
      <el-form-item label="A/B方">
        <el-input style="width: 200px" placeholder="请输入性别或阵营颜色" v-model="this.account.gender" clearable />
      </el-form-item>
      <el-form-item label="账号类型">
        <el-select v-model="accountType" style="width: 200px" placeholder="选择账号类型">
          <el-option v-for="item in accountList" :key="item.value" :label="item.label" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="任务描述" style="height: 100px;">
        <el-input style="width: 200px;height: 200px;" type="textarea" :rows="5"
          placeholder="举例，任务描述：2024年9月26日上午8：00 完成男子47kg级别的称重，地址亚运村12栋301" v-model="this.account.introduce" clearable />
      </el-form-item>
    </el-form>
    <el-button link type="primary" style="margin-left: 350px;" @click="addAccount">确定添加</el-button>
    <el-button link type="danger" style="margin-left: 20px;" @click="this.diaCheckShow = false">取消</el-button>
  </el-dialog>
  <el-dialog v-model="weightbind" title="绑定项目" width="500" :before-close="handleClose">
    <el-form label-width="120">
      <el-form-item label="请选择组别">
        <el-select v-model="constituencyId" placeholder="请选择" style="width: 200px">
          <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="请选择比赛级别">
        <el-cascader :options="options" v-model="lotteryMode" placeholder="选择比赛级别"
          style="width: 200px; margin-left: 20px;" filterable />
      </el-form-item>
      <el-form-item label="请选择公斤级">
        <el-input style="width: 200px;" placeholder="公斤级" v-model="level"></el-input>
      </el-form-item>
    </el-form>
    <el-button link type="primary" style="margin-left: 350px;" @click="weightaccountbind">确定绑定</el-button>
    <el-button link type="danger" style="margin-left: 20px;" @click="this.weightbind = false">取消</el-button>
  </el-dialog>
  <el-dialog v-model="checkbind" title="绑定项目" width="500" :before-close="handleClose">
    <el-form label-width="120">
      <el-form-item label="请选择场地 ">
        <el-select v-model="fieldid" placeholder="请选择" style="width: 200px">
          <el-option v-for="item in fieldList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        </el-form-item>
    </el-form>
    <el-button link type="primary" style="margin-left: 350px;" @click="checkaccountbind">确定绑定</el-button>
    <el-button link type="danger" style="margin-left: 20px;" @click="this.checkbind = false">取消</el-button>
  </el-dialog>
  <el-dialog title="提示" width="30%" v-model="editpassword">
    <el-form label-width="70">
      <el-form-item label="账号&nbsp;&nbsp;">
        <el-input class="input" v-model="account.password" clearable type="password" show-password
          placeholder="请输入新密码"></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="edit1">确 定</el-button>
    <el-button @click="cancel">取 消</el-button>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      itemList: "",
      diaCheckShow: false,
      accountType: '检录',
      editpassword: false,
      page: 1,
      size: 5,
      total: 0,
      constituencyId: '',
      fieldid:'',
      grouplist: [],
      fieldList:[],
      level: '',
      account: {
        account: "",
        password: "",
        place: "",
        gender: "",
        introduce: "",
        id: "",
        accountType: '',
      },
      options: [
        {
          value: '团体',
          label: '团体',
          children: [
            { value: '男子', label: '男子' },
            { value: '女子', label: '女子' }
          ]
        },
        {
          value: '个人',
          label: '个人',
          children: [
            { value: '男子', label: '男子' },
            { value: '女子', label: '女子' }
          ]
        }
      ],
      weightbind: false,
      checkbind: false,
      lotteryMode: '',
      accountData: [],
      accountClassList: [
        { value: "检录", label: "检录账号" },
        { value: "称重", label: "称重账号" },
      ],
      accountList: [{ value: "检录", label: "检录" },
      { value: "称重", label: "称重" }]
    };
  },
  created() {
    this.getAllAccounts();
    this.getallgroup();
    this.getallfield();
  },
  computed: {
    Classifyaccount() {
      if (!this.accountType) {
        return this.accountData;
      }
      return this.accountData.filter(account => account.accountType === this.accountType);
    }
  },
  methods: {
    async getallfield() {
      const { data } = await axios.get(this.$globalurl + "/field/getAll");
      this.fieldList = data
    },
    bindaccount(item) {
      if (item.accountType === '称重') {
        this.weightbind = true;
      }else if(item.accountType==='检录'){
        this.checkbind=true;
      }
      this.account.id = item.id;
    },
    async weightaccountbind() {
      try {
        const { level, lotteryMode, constituencyId } = this;
        const jsonData = {
          gameId: localStorage.getItem("comId"),
          name: localStorage.getItem("itemName"),
          type: lotteryMode[0],
          level: level + 'kg',
          gender: lotteryMode[1],
          constituencyId: constituencyId
        };
        const jsonString = JSON.stringify(jsonData);
        const response = await axios.post(this.$globalurl + "comItem/getByCondition", jsonString, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data) {
          const response1 = await axios.get(this.$globalurl + "weighAccount/setComItemForWeighAccount", {
            params: {
              comItemId: response.data[0].comItemId,
              accountId: this.account.id
            }
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response1.data == true) {
            this.$message.success('绑定成功');
            this.weightbind=false;
          }
          else {
            this.$message.error('绑定失败');
            this.weightbind=false;
          }
        }
      } catch (error) {
        this.$message.error('条件不全或项目不存在!!!');
      }
    },
    async checkaccountbind(){
      const {fieldid}=this;
      const response= await axios.get(this.$globalurl+"/checkAccount/bindCheckAccountIdAndfieldId", {
            params: {
              fieldId: fieldid,
              accountId: this.account.id
            }
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.data == true) {
            this.$message.success('绑定成功');
            this.checkbind=false
          }
          else{
            this.$message.error('绑定失败');
            this.checkbind=false;
          }
    },
    async getallgroup() {
      const { data } = await axios.get(this.$globalurl + "/constituency/getAll");
      this.grouplist = data;
    },
    async getAllAccounts() {
      const { page, size } = this;

      try {
        const [weightResponse, checkResponse] = await Promise.all([
          axios.get(this.$globalurl + "/weighAccount/weighAccountListGet", {
            params: { currentPage: page, pageSize: size }
          }),
          axios.get(this.$globalurl+"/checkAccount/checkAccountListGet", {
            params: { currentPage: page, pageSize: size }
          })
        ]);

        const weightAccounts = weightResponse.data.records.map(account => ({
          ...account,
          accountType: '称重'
        }));
        console.log(checkResponse.data);
        const checkAccounts = checkResponse.data.records.map(account => ({
          ...account,
          accountType: '检录',
          gender: account.ab
        }));

        this.accountData = [...weightAccounts, ...checkAccounts];
        this.total = weightResponse.data.total + checkResponse.data.total;
      } catch (error) {
        this.$message.error('获取数据失败')
      }
    },
    async addAccount() {
      const { account, accountType } = this;
      const jsonData = {
        account: account.username,
        password: account.password,
        place: account.place,
        introduce: account.introduce,
        gender: account.gender,
        id: 0
      };
      const jsonData1 = {
        account: account.username,
        password: account.password,
        place: account.place,
        introduce: account.introduce,
        ab: account.gender,
        id: 0
      };
      console.log(accountType);
      const jsonString = JSON.stringify(jsonData);
      const jsonString1 = JSON.stringify(jsonData1);
      if (accountType === '称重') {
        const { data } = await axios.post(this.$globalurl + "/weighAccount/add", jsonString, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (data === true) {
          this.$message.success("添加账号成功");
          this.diaCheckShow = false;
          this.getAllAccounts();
        }
        else {
          this.$message.error("添加账号失败");
          this.diaCheckShow = false;
        }
      }
      else {
        const { data } = await axios.post(this.$globalurl+"/checkAccount/add", jsonString1, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (data === true) {
          this.$message.success("添加账号成功");
          this.diaCheckShow = false;
          this.getAllAccounts();
        }
        else {
          this.$message.error("添加账号失败");
          this.diaCheckShow = false;
        }
      }
    },
    async deleteAccount(item) {
      const { id, accountType } = item
      if (accountType === '称重') {
        const { data } = await axios.get(this.$globalurl + `weighAccount/deleteById/${id}`);
        if (data === true) {
          this.$message.success("删除成功!");
          this.getAllAccounts();
        }
        else {
          this.$message.error("删除失败");
        }
      } else {
        const { data } = await axios.get(this.$globalurl+`/checkAccount/deleteById/${id}`);
        if (data === true) {
          this.$message.success("删除成功!");
          this.getAllAccounts();
        }
        else {
          this.$message.error("删除失败");
        }
      }
    },

    // toCheckAthletes(item) {
    //   console.log(item);
    //   let routeUrl = this.$router.resolve({
    //     path: "/ToCheckAthletesPage",
    //     query: { competitionInformationId: item.id },
    //   });
    //   window.open(routeUrl.href, "_blank");
    // },
    addCheckInformation() {
      this.diaCheckShow = true;
    },
    edit(item) {
      this.account.id = item.id;
      this.account.accountType = item.accountType;
      this.editpassword = true;
    },
    async edit1() {
      const { password, id, accountType } = this.account;
      if (accountType === '称重') {
        const { data } = await axios.post(this.$globalurl + `weighAccount/alterPwd?id=${id}&password=${password}`);
        if (data === true) {
          this.$message.success("修改成功!");
          this.editpassword = false;
          this.getAllAccounts();
        } else {
          this.$message.arror("删除失败!");
          this.editpassword = false;
        }
      }
      else {
        const { data } = await axios.post(this.$globalurl+`/checkAccount/alterPwd?id=${id}&password=${password}`);
        if (data === true) {
          this.$message.success("修改成功!");
          this.editpassword = false;
          this.getAllAccounts();
        } else {
          this.$message.arror("删除失败!");
          this.editpassword = false;
        }
      }
    },
    handleSizeChange(val) {
      this.size = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getAllaccount();
    },
    cancel() {
      this.editpassword = false;
      this.password = ""
    },
  },
};
</script>

<style>
.el-pagination__total {
  color: #3b10c6bf;

}

.el-pager li {
  background: #f3f5f78a;
  border: 1px solid #eb1111a9;
  color: #1ae535;
}

.el-input__inner {
  line-height: 1px !important;
}

/* .el-pager li:hover {
 background: #f4f5f5;
 color: #adb3b7;
} */
/* .el-pagination button[disabled="disabled"] {
 background: none;
 color: #01e1edbf;
} */
.el-pagination__jump {
  color: #e01d1dbf;
}

.el-textarea {
  height: 100px;
}
</style>