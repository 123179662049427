<template>
  <el-row>
    <el-upload
      class="upload-excel"
      action="#"
      :on-change="handleChange"
      :show-file-list="false"
    >
      <el-button type="primary">点击上传 Excel 文件</el-button>
    </el-upload>
    <el-button type="" style="margin-left: 20px" @click="downloadExcel">
      下载模版
    </el-button>
    <el-button @click="submit()">提交</el-button>
  </el-row>
  <el-row>
    <el-table
        
      ref="tableRef"
      :data="tableObject"
      style="width: 100%;cursor: pointer;"
      v-if="tableData.length > 0"
      @row-click="seleceRowInformation"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="key in this.tableKeys"
        :key="key"
        :prop="key"
        :label="key"
      ></el-table-column>
    </el-table>
  </el-row>
  <!-- 编辑信息 -->
  <el-dialog v-model="diaTable" title="编辑数据" style="width: 600px;">
    <el-form :model="currentRow" label-width="150">
      <el-form-item  v-for="key in tableKeys" :key="key" :label="key" style="width: 400px;">
        <el-input :disabled="key=='gameId' || key=='userId' || key=='userType'" v-model="currentRow[key]"></el-input>
      </el-form-item>
    </el-form>
    <span  class="dialog-footer">
      <el-button @click="diaTable = false">取消</el-button>
      <el-button type="primary" @click="saveRow">保存</el-button>
    </span>
  </el-dialog>
</template>
  
  <script>
import axios from "axios";
import * as XLSX from "xlsx"; // 确保这个库被使用

export default {
  data() {
    return {
      tableData: [], // 存储每一行数据的对象列表
      tableKeys: [], // 存储对象键的数组
      tableObject: [],
      keyName: [
        "num",
        "name",
        "teamName",
        "birthDay",
        "cardType",
        "cardId",
        "comItemName",
        "gender",
        "level",
        "constituencyName",
        "phone",
        "gameId",
        "userId",
        "userType",
      ],
      tableRef: "",
      diaTable:false,
      currentRow:''
    };
  },
  methods: {
    seleceRowInformation(row){
        this.currentRow = row;
        this.diaTable=true
    },
    saveRow(){
   
            // 查找当前行的数据索引
      console.log(this.currentRow);
      const index = this.tableObject.findIndex(item => item === this.currentRow);
      // 更新该行的数据
      console.log(index);
      console.log(this.tableObject);
      if (index !== -1) {
        // this.tableData.splice(index, 1, this.currentRow);
        this.tableData[index] = this.currentRow
        this.diaTable = false;
        this.$message({
              type: "success",
              message: "修改成功",
            });
      }
    },

    // 下载excel 模版
    async downloadExcel() {
      try {
        const response = await fetch("/template.xlsx");
        if (!response.ok) {
          throw new Error("Failed to fetch template");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading the template:", error);
      }
    },
    // 获取传来的excel文件
    uploadExcel(options) {
      const file = options.file;
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          var json = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            raw: true,
          });
          json = json.filter((row) => row.some((cell) => cell !== ""));
          console.log(json[0]);

          this.tableData = json.slice(1); // 跳过标题行，只保留数据行
          this.tableKeys = this.keyName;
        };
        reader.readAsArrayBuffer(file);
        console.log(this.tableKeys);
        console.log(localStorage.getItem("comId"));
        this.tableData = this.tableData.map((subMarray) => {
          return [
            ...subMarray,
            localStorage.getItem("comId"),
            localStorage.getItem("userId"),
            localStorage.getItem("userType"),
          ];
        });
        this.tableObject = this.tableData.map((item) => {
          return this.tableKeys.reduce((obj, key, index) => {
            obj[key] = item[index];
            return obj;
          }, {});
        });
        console.log(this.tableObject);
        this.$nextTick(() => {
          if (this.$refs.tableRef) {
            this.$refs.tableRef.toggleAllSelection();
          }
        });
      } else {
        this.$message.error("上传的文件不是一个有效的 Blob 或 File 对象");
      }
    },
    // 文件被改变的时候进行的方法
    handleChange(file) {
      if (file.raw) {
        this.uploadExcel({ file: file.raw });
      }
    },
    // 提交申请报名
    submit() {
      var selectRow = "";
      if (this.$refs.tableRef) {
        selectRow = this.$refs.tableRef.getSelectionRows();
        console.log(selectRow);
      }
      if (selectRow.length == 0) {
        this.$message({
          type: "error",
          message: "请选择名单",
        });
      } else {
        var that = this;
        axios({
          url: that.$globalurl + "import/individualApplicationApply",
          method: "post",
          data: selectRow,
          headers: {
            "content-type": "application/json",
          },
        }).then(function (response) {
          console.log(response.data);
          if (response.data.type == "Success") {
            that.$message({
              type: "success",
              message: "提交成功",
            });
          } else {
            
            that.$message({
              type: "error",
              message: "提交失败",
            });
          }
        });
      }
    },
  },
};
</script>
  
  <style>
.upload-excel .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
}
.upload-excel .el-upload:hover {
  border-color: #409eff;
}
</style>