<template>
  <!-- 显示比赛 -->
  <el-row>
    <el-radio-group v-model="filterOption" style="width: 240px; margin-left: 20px;margin-top: 20px;">
      <el-radio :label="true">随机称重</el-radio>
      <el-radio :label="false">显示全部</el-radio>
    </el-radio-group>
    <el-select v-model="constituencyId" placeholder="请选择" style="width: 240px;margin-top: 20px;">
      <!-- 通过 v-for 遍历选项数据 -->
      <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
    <el-cascader :options="options" v-model="lotteryMode" placeholder="选择比赛级别"
      style="width: 240px; margin-left: 20px; margin-right: 20px;margin-top: 20px;" filterable />
    <el-input style="width: 100px; margin-right: 20px;margin-top: 20px;" placeholder="公斤级" v-model="level"></el-input>
    <el-button style="width: 100px; margin-right: 20px;margin-top: 20px;" @click="search()">查询</el-button>
    <el-button link type="primary" style="margin-left: 20px; margin-top: 20px;" @click="Print()">打印称重信息</el-button>
  </el-row>
  <el-table :data="filteredChecklist" style="width: 100%;margin-left: 50px;">
    <el-table-column label="序号" width="60">
      <template v-slot="scope">
        {{ String(scope.$index + 1).padStart(3, '0') }}
      </template>
    </el-table-column>
    <el-table-column prop="comItemName" label="级别" />
    <el-table-column prop="teamName" label="代表队" />
    <el-table-column prop="athleteName" label="运动员姓名" />
    <el-table-column prop="weighingDate" label="称重时间" />
    <el-table-column prop="judgeName" label="称重员账号" />
    <el-table-column prop="weight" label="第一次称重数据" />
    <el-table-column prop="Weight2" label="第二次称重数据" />
    <el-table-column label="随机称重">
      <template v-slot="scope">
        {{ scope.row.type === "普通" ? '否' : scope.row.type === "随机" ? "是" : "未知" }}
      </template>
    </el-table-column>
    <el-table-column label="称重结果" width="150">
      <template v-slot="scope">
        {{ scope.row.result ? '已称重' : '未称重' }}
      </template>
    </el-table-column>
    <el-table-column label="操作" width="160">
      <template v-slot="scope">
        <el-button link type="primary" @click="editweightresult(scope.row)">修改结果</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="修改称重结果" width="30%" v-model="editweight">
    <el-form label-width="100">
    <el-form-item label="新称重结果">
        <el-select v-model="checkWeight.result" style="width: 200px" placeholder="选择结果">
          <el-option v-for="item in resultList" :key="item.value" :label="item.label" :value="item" />
        </el-select>
      </el-form-item>
    </el-form>
      <el-button type="primary" @click="edit1">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
  </el-dialog>
  <!-- <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
    :current-page="page" :page-sizes="[5, 10, 15, 20]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
    :total="total" style="margin-top: 50px;justify-content: center;">
  </el-pagination> -->
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      checkWeight: {
        No: '',
        comItemName: '',
        teamName: '',
        athleteName: '',
        weighingDate: '',
        judgeName: '',
        weight: '',
        Weight2: '',
        type: false,
        result: '',
        id:'',
      },
      filterOption: false,
      page: 1,
      size: 5,
      total: 20,
      level: '',
      editweight:false,
      constituencyId: '',
      checkWeightData: [],
      grouplist: [],
      locatonList: [],
      resultList:[
      { value: true, label: "已称重" },
      { value: false, label: "未称重" },
      ],
      options: [
        {
          value: '团体',
          label: '团体',
          children: [
            { value: '男子', label: '男子' },
            { value: '女子', label: '女子' }
          ]
        },
        {
          value: '个人',
          label: '个人',
          children: [
            { value: '男子', label: '男子' },
            { value: '女子', label: '女子' }
          ]
        }
      ]
    }
  },
  computed: {
    filteredChecklist() {
      if (this.filterOption) {
        this.search();
      }
      return this.checkWeightData;
    },
  },
  created() {
    this.getAllWeightData();
    this.getallgroup();
  },
  methods: {
    async getAllWeightData() {
      const { data } = await axios.get(this.$globalurl + "/weigh/getAll");
      this.checkWeightData = data.map(checkWeight => {
        return {
          ...checkWeight,
          Weight2: 0,
          weighingDate: this.formatDate(checkWeight.weighingDate)
        }
      })
    },
    async getallgroup(){
      const { data } = await axios.get(this.$globalurl + "/constituency/getAll");
      this.grouplist=data;
    },
    toCheckAthletes(item) {
      console.log(item);
      let routeUrl = this.$router.resolve({
        path: '/ToCheckAthletesPage',
        query: { competitionInformationId: item.id }
      })
      window.open(routeUrl.href, '_blank');
    },
    editweightresult(item){
      this.checkWeight.id=item.id;
      console.log(this.checkWeight.id)
      this.editweight=true
    },
    cancel() {
      this.editweight = false;
    },
    async edit1() {
      console.log(this.checkWeight.result)
      const { data } = await axios.get(this.$globalurl+"/weigh/alterResultById",{
        params:{
          weighId:this.checkWeight.id,
          result:this.checkWeight.result==='已称重'?true:false
        }
      });
      if(data===true){
        this.$message.success("修改成功!");
        this.editweight=false;
        this.getAllWeightData();
      }else{
        this.$message.arror("修改失败!");
        this.editweight=false;
      }
    },
    //分页
    // handleSizeChange(val) {
    //   this.size = val;
    //   this.getTableData();
    // },
    // handleCurrentChange(val) {
    //   this.page = val;
    //   this.getTableData();
    // },
    async Print() {
      try {
        const { level, lotteryMode, constituencyId } = this;
      const jsonData = {
        gameId: localStorage.getItem("comId"),
        name:localStorage.getItem("itemName"),
        type: lotteryMode[0],
        level: level+'kg',
        gender: lotteryMode[1],
        constituencyId: constituencyId
      };
      const jsonString = JSON.stringify(jsonData);
      const response = await axios.post(this.$globalurl + "comItem/getByCondition", jsonString, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      this.$router.push({
        path: '/ToReporttemplatePage',
        query: { comItemId: response.data[0].comItemId,},
      });
      } catch (error) {
        this.$message.error('请先选择分类再打印');
      }
    },
    async search() {
      try {
        const { level, lotteryMode, constituencyId } = this;
      const jsonData = {
        gameId: localStorage.getItem("comId"),
        name:localStorage.getItem("itemName"),
        type: lotteryMode[0],
        level: level+'kg',
        gender: lotteryMode[1],
        constituencyId: constituencyId
      };
      const jsonString = JSON.stringify(jsonData);
      const response = await axios.post(this.$globalurl + "comItem/getByCondition", jsonString, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if(response.data){
        const response1 = await axios.get(this.$globalurl + "weigh/getByComItemId", {
          params:{
            comItemId:response.data[0].comItemId,
            type:this.filterOption==false?'普通':'随机',
          }
        }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      this.checkWeightData = response1.data.map(checkWeight => {
        return {
          ...checkWeight,
          Weight2: 0,
          weighingDate: this.formatDate(checkWeight.weighingDate)
        }
      })
      }
      } catch (error) {
        this.$message.error('查询条件不全!!!');
      }

    },
    formatDate(isoString) {
      const date = new Date(isoString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style>
.el-pagination__total {
  color: #3b10c6bf;

}

.el-pager li {
  background: #f3f5f78a;
  border: 1px solid #eb1111a9;
  color: #1ae535;
}

.el-input__inner {
  line-height: 1px !important;
}

/* .el-pager li:hover {
 background: #f4f5f5;
 color: #adb3b7;
} */
/* .el-pagination button[disabled="disabled"] {
 background: none;
 color: #01e1edbf;
} */
.el-pagination__jump {
  color: #e01d1dbf;
}
</style>