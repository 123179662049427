<template>
  <!-- 显示比赛 -->
  <el-row>
    <el-select v-model="accountId" placeholder="选择账号"
      style="width: 120px; margin-left: 20px; margin-right: 20px;margin-top: 20px;">
      <el-option v-for="item in userList" :key="item.id" :label="item.account" :value="item.id" />
    </el-select>
    <el-select v-model="ab" placeholder="选择A/B方"
      style="width: 120px; margin-left: 20px; margin-right: 20px;margin-top: 20px;">
      <el-option v-for="item in groupList" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-button style="width: 100px; margin-right: 20px;margin-top: 20px;" @click="getAllcheckbyuser()">查询</el-button>
    <!-- <el-select v-model="lotterMode" placeholder="选择阵营"
      style="width: 120px; margin-left: 20px; margin-right: 20px;margin-top: 20px;">
      <el-option v-for="item in groupList" :key="item.value" :label="item.label" :value="item.value" />
    </el-select> -->
    <el-button link type="primary" style="margin-left: 20px; margin-top: 20px;" @click="Printcheck()">打印检录信息</el-button>
  </el-row>
  <el-table :data="checkInfoData" style="width: 100%;margin-left: 50px;">
    <el-table-column label="序号">
      <template v-slot="scope">
        {{ String(scope.$index + 1).padStart(3, '0') }}
      </template>
    </el-table-column>
    <el-table-column prop="fieldName" label="场地" />
    <el-table-column prop="level" label="级别" />
    <el-table-column prop="ab" label="阵营" />
    <el-table-column prop="groupName" label="队伍" />
    <el-table-column prop="athleteName" label="运动员姓名" />
    <el-table-column prop="result" label="检录结果" />
    <el-table-column label="操作" width="160">
      <template v-slot="scope">
        <el-button link type="primary" @click="edit(scope.row.id)">修改结果</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="修改检录结果" width="30%" v-model="editcheck">
    <el-form label-width="100">
      <el-form-item label="新检录结果">
        <el-select v-model="checkinfo.result" style="width: 200px" placeholder="选择结果">
          <el-option v-for="item in resultList" :key="item.value" :label="item.label" :value="item" />
        </el-select>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="edit1">确 定</el-button>
    <el-button @click="cancel">取 消</el-button>
  </el-dialog>
  <!-- <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
    :current-page="page" :page-sizes="[5, 10, 15, 20]" :page-size="size"
    layout="total, sizes, prev, pager, next, jumper" :total="total" style="margin-top: 50px;justify-content: center;">
  </el-pagination> -->
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      checkinfo: {
        id: '',
        fieldName: '',
        groupName: '',
        judgerName: '',
        athleteName: '',
        result: '',
      },
      userList: [],
      resultList: [
        { value: true, label: "已检录" },
        { value: false, label: "待检录" },
      ],
      groupList: [
        { value: 'A', label: "青方" },
        { value: 'B', label: "红方" },
        { value: 'AB', label: "青红方" },
      ],
      page: 1,
      size: 5,
      total: 20,
      editcheck: false,
      checkInfoData: [],
      accountId:'',
      ab:''
    }
  },
  created() {
    this.getallcheck();
    this.getalluser();
 
  },
  methods: {
    async getalluser() {
      const { data } = await axios.get(this.$globalurl + "/checkAccount/getAll");
      this.userList = data
    },
    async getallcheck(){
      const { data } = await axios.get(this.$globalurl + "/check/getAll");
      this.checkInfoData = data.map(check => {
        return {
          ...check,
          level:check.comItemName+check.level,
          ab:check.ab==='A'?'青方':check.ab==='B'?'红方':'青红方'
        }
      })
      console.log(this.checkInfoData);
    },
    async getAllcheckbyuser() {
      try {
        const {accountId,ab}=this;
        const { data } = await axios.get(this.$globalurl + "check/getByCheckAccountId",{
          params:{
            ab:ab,
            accountId:accountId
          }
        });
        this.checkInfoData = data.map(check => {
        return {
          ...check,
          level:check.comItemName+check.level,
          ab:check.ab==='A'?'青方':check.ab==='B'?'红方':'青红方'
        }
      })
      } catch (error) {
        this.$message.error('查询失败')
      }
    },
    toCheckAthletes(item) {
      console.log(item);
      let routeUrl = this.$router.resolve({
        path: '/ToCheckAthletesPage',
        query: { competitionInformationId: item.id }
      })
      window.open(routeUrl.href, '_blank');
    },
    async Printcheck() {
  const { ab, accountId } = this;
  if (!ab || !accountId) {
    this.$message.error('请选择账号和输入A/B方');
    return; 
  }
  this.$router.push({
    path: '/ToReporttemplatePage',
    query: { comItemId: 236, ab: ab, accountId: accountId },
  });
},
    async edit1(){
      const{result,id}=this.checkinfo
      console.log(result)
      console.log(id)
      const { data } = await axios.get(this.$globalurl+"/check/alterResultById",{
        params:{
          checkId:id,
          result:result.value
        }
      });
      if(data===true){
        this.$message.success("修改成功!");
        this.editcheck=false;
        this.getallcheck();
      }else{
        this.$message.arror("修改失败!");
        this.editcheck=false;
      }
    },
    edit(id) {
      this.checkinfo.id = id;
      console.log(this.checkinfo.id);
      this.editcheck = true;
    },
    cancel() {
      this.editcheck = false;
    }
    // handleSizeChange(val) {
    //   this.size = val;
    //   this.getTableData();
    // },
    // handleCurrentChange(val) {
    //   this.page = val;
    //   this.getTableData();
    // },
  }
}
</script>

<style>
.el-pagination__total {
  color: #3b10c6bf;

}

.el-pager li {
  background: #f3f5f78a;
  border: 1px solid #eb1111a9;
  color: #1ae535;
}

.el-input__inner {
  line-height: 1px !important;
}

/* .el-pager li:hover {
   background: #f4f5f5;
   color: #adb3b7;
  } */
/* .el-pagination button[disabled="disabled"] {
   background: none;
   color: #01e1edbf;
  } */
.el-pagination__jump {
  color: #e01d1dbf;
}
</style>