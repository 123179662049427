import { createRouter, createWebHashHistory } from 'vue-router'
import OperationPage from '@/views/OperationPage' // 柔术 操作页面
import CompetitionPage from '@/views/CompetitionPage' // 柔术毕业展示页面
import RedWinPage from "@/views/RedWinPage";  // 柔术蓝方胜利页面
import BlueWinPage from "@/views/BlueWinPage"; // 柔术红方胜利页面
import LoginPage from '@/views/LoginPage.vue' // 登入页面
import RegistrationPage from '@/views/RegistrationPage.vue'  // 注册页面
import HomeView from '@/views/HomeView.vue';
import MainPage from '@/views/MainPage.vue'; // 主页面
import HomePage from '@/views/HomePage.vue';   // 首页
import PersonalInformation from '@/views/PersonalInformationPage.vue'  // 个人信息页面
import CompetitionManagement from '@/views/CompetitionManagementPage.vue'  // 比赛管理页面
import OpponentChartPage from '@/views/OpponentChartPage.vue';  // 对阵图页面
import ConfigurationCompetitionPage from '@/views/ConfigurationCompetitionPage.vue' // 比赛配置页面
import LotteryPage from '@/views/LotteryPage.vue' // 抽签页面
import SignUpPage from '@/views/SignUpPage.vue'  // 比赛报名页面
import CompetitionInformation from '@/views/CompetitionInformationPage.vue' // 比赛信息页面
import ComMainPage from '@/views/ComMainPage.vue'; // 比赛主页面
import SelectItemPage from '@/views/SelectItemPage.vue'; // 选择比赛页面
import RegistrationInformationPage from '@/views/RegistrationInformationPage.vue'; // 报名填写信息页面
import GameManagementPage from '@/views/GameManagementPage.vue'; // 赛事配置比赛页面
import CheckPage from '@/views/CheckPage.vue';  // 审核页面
import DelegationManagementPage from '@/views/DelegationManagementPage.vue'; // 代表队管理
import DelegationCheckPage from '@/views/DelegationCheckPage.vue'; // 代表队审核
import DelegationPage from '@/views/DelegationPage.vue'; // 代表队页面
import DelegationSignUpCheckPage from '@/views/DelegationSignUpCheckPage.vue';
import OpponentChartInformationPage from '@/views/OpponentChartInformationPage.vue';
import TemplateviewPage from '@/views/TemplateviewPage.vue';
import SingleFailurePrinting from '@/views/SingleFailurePrinting.vue';
import LocationManager from '@/views/LocationManager.vue'; //场地页面
import ReporttemplatePage from '@/views/ReporttemplatePage.vue';
import CheckInManagementPage from '@/views/CheckInManagementPage.vue';
import CheckAthletesPage from '@/views/CheckAthletesPage.vue';
import AccountManagementPage from '@/views/AccountManagementPage.vue';
import TeamLeaderRegistrationPage from '@/views/TeamLeaderRegistrationPage.vue';
import UnitordertablePage from '@/views/UnitordertablePage.vue';
import PrintMangePage from '@/views/PrintMangePage.vue';
import UnitscoretablePage  from '@/views/UnitscoretablePage.vue';
import WeightManagementPage from '@/views/WeightManagementPage.vue';// 称重
import CheckManagementPage from '@/views/CheckManagementPage.vue'; // 检录
const routes = [
    // {
    //     path: "/",
    //     redirect: '/ToCompetitionPage'
    // }，  
    
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage
    },
    {path:'/ToUnitordertablePage',component:UnitordertablePage},
    {path:'/ToUnitscoretablePage',component:UnitscoretablePage},
    // 去检录页面
    {
        path:'/ToCheckAthletesPage',
        name:'CheckAthletesPage',
        component:CheckAthletesPage
    },
    {
        path: '/ToTemplateviewPage',
        name: 'TemplateviewPage',
        component: TemplateviewPage
    },
    {path:'/ToReporttemplatePage',component:ReporttemplatePage},
    
    {
        path: '/ToRegistrationInformationPage',
        name: 'RegistrationInformationPage',
        component: RegistrationInformationPage
    },
    {
        path:'/ToComMainPage',
        component:ComMainPage,
        children:[
            {path: '/ToConfigurationCompetitionPage',component: ConfigurationCompetitionPage},
            {path:'/ToOpponentChartPage',component:OpponentChartPage},
            {path:'/ToLotteryPage',component:LotteryPage},
            {path: '/ToCheckPage',component: CheckPage},
            {path:'/ToOpponentChartInformationPage',component:OpponentChartInformationPage},
            {path:'/ToSingleFailurePrinting',component:SingleFailurePrinting},
            {path:'/ToLocationManager',component:LocationManager},
            {path:'/ToDelegationManagementPage',component:DelegationManagementPage},
            {path:'/ToDelegationCheckPage',component:DelegationCheckPage},
            {path:'/ToDelegationSignUpCheckPage',component:DelegationSignUpCheckPage},
            {path:'/ToDelegationPage',component:DelegationPage},
            {path:'/ToCheckInManagementPage',component:CheckInManagementPage},
            {path:'/ToTeamLeaderRegistrationPage',component:TeamLeaderRegistrationPage},
            {path:'/ToPrintMangePage',component:PrintMangePage},
            {path:'/ToAccountManagementPage',component:AccountManagementPage},
            {path:'/ToWeightManagementPage',component:WeightManagementPage},
            {path:'/ToCheckManagementPage',component:CheckManagementPage}

        ]
        
    },
    {
        path: '/HomeView',
        name: 'HomeView',
        component: HomeView,
     
    },
    {
        path: '/ToCompetitionInformation',
        name: 'CompetitionInformation',
        component: CompetitionInformation,
     
    },
    {
        path: '/ToMainPage',
        name: 'MainPage',
        component: MainPage,
        children:[
            {path: '/ToHomePage',component: HomePage},
            {path:'/ToPersonalInformationPage',component:PersonalInformation},
            {path:'/ToCompetitionManagementPage',component:CompetitionManagement},
            {path:'/ToSignUpPage',component:SignUpPage},
            {path:'/ToSelectItemPage',component:SelectItemPage},
            {path:'/ToGameManagementPage',component:GameManagementPage},
           

          
         
        
        ]
    },
    {
        path:'/ToRegistrationPage',
        name:'RegistrationPage',
        component: RegistrationPage
    },
    {
        path: '/ToOperationPage',
        name: 'OperationPage',
        component: OperationPage
    },
    
    {
        path: '/ToCompetitionPage',
        name: 'CompetitionPage',
        component: CompetitionPage
    },
    {
        path: '/ToRedWinPage',
        name: 'RedWinPage',
        component: RedWinPage
    },
    {
        path: '/ToBlueWinPage',
        name: 'BlueWinPage',
        component: BlueWinPage
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router