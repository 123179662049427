<!-- components/ImageViewer.vue -->
<template>
    <div class="btncontainer">
        <!-- <button @click="backPage">关闭</button>
        <button v-print="'#print-content'">打印</button> -->
        <span>{{ modeltype }}</span>
        <select v-model="reportype" @change="SelectChange">
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <select v-model="num" @change="numChange">
            <option v-for="option in numlist" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <!-- <el-button @click="backPage" type="primary">关闭</el-button> -->
        <el-button v-print="'#print-content'" type="success">打印</el-button>
        <!-- <button @click="test">打印</button> -->
    </div>
    <div class="image-viewer" id="print-content">
        <!-- <div class="modelcomtainer"> -->
        <div v-if="modeltype == '报名表(团队)'">
            <div class="modelcomtainer" v-for="(Item, index) in team_applylist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">{{ modeltype }}</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="6" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="6" class="tipsstyle">
                            <span class="tipsfontstyle">队名</span>
                        </el-col>
                        <el-col :span="6" class="tipsstyle">
                            <span class="tipsfontstyle">成员名字</span>
                        </el-col>
                        <!-- <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col> -->
                        <el-col :span="6" class="tipsstyle">
                            <span class="tipsfontstyle">出生日期</span>
                        </el-col>
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div style="display: flex;width: 98%;margin-left: 1%;margin-top: 2%; margin-bottom: 3%;"
                        v-for="(item) in Item" :key="item.id">
                        <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                        <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.groupName }}</span>
                        </el-col>
                        <el-col :span="6"
                            style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <div v-for="(althle) in item.athleteList" :key="althle.id">
                                <span style=" font-size: small;font-weight: bold;">{{ althle.name }}</span>
                            </div>
                        </el-col>
                        <!-- <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                        </el-col> -->
                        <el-col :span="6"
                            style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <div v-for="(althle) in item.athleteList" :key="althle.id">
                                <span style=" font-size: small;font-weight: bold;">{{ althle.birthday }}</span>
                            </div>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ team_applylist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '报名表(个人)'">
            <div class="modelcomtainer" v-for="(Item, index) in person_applylist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;margin-bottom: 0px;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">{{ modeltype }}</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <!-- <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">种子号</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col> -->
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">出生日期</span>
                        </el-col>
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div class="detailmsgcontainer" v-for="(item) in Item" :key="item.id">
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.athleteList[0].name }}</span>
                        </el-col>
                        <!-- <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                        </el-col>
                        <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                        </el-col> -->
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.athleteList[0].birthday }}</span>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ person_applylist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '参赛表(个人)'">
            <div class="modelcomtainer" v-for="(Item, index) in person_entrylist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">{{ modeltype }}</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="6" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="6" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">场次号</span>
                        </el-col>
                        <el-col :span="5" class="tipsstyle">
                            <span class="tipsfontstyle">出生日期</span>
                        </el-col>
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div class="detailmsgcontainer" v-for="(item) in Item" :key="item.id">
                        <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                        <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.athleteList[0].name }}</span>
                        </el-col>
                        <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.drawNum }}</span>
                        </el-col>
                        <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.number }}</span>
                        </el-col>
                        <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.athleteList[0].birthday }}</span>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ person_entrylist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '参赛表(团队)'">
            <div class="modelcomtainer" v-for="(Item, index) in team_entrylist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">{{ modeltype }}</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">场次号</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">成员</span>
                        </el-col>
                        <el-col :span="5" class="tipsstyle">
                            <span class="tipsfontstyle">出生日期</span>
                        </el-col>
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div class="detailmsgcontainer" v-for="(item) in Item" :key="item.id">
                        <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                        <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.groupName }}</span>
                        </el-col>
                        <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.drawNum }}</span>
                        </el-col>
                        <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.number }}</span>
                        </el-col>
                        <el-col :span="4"
                            style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <div v-for="(althle) in item.athleteList" :key="althle.id">
                                <span style=" font-size: small;font-weight: bold;">{{ althle.name }}</span>
                            </div>
                        </el-col>
                        <el-col :span="5"
                            style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <div v-for="(althle) in item.athleteList" :key="althle.id">
                                <span style=" font-size: small;font-weight: bold;">{{ althle.birthday }}</span>
                            </div>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ team_entrylist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '综合秩序表'">
            <div class="modelcomtainer" v-for="(Item, index) in comprehensive_orderlist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">综合秩序表</span>
                    </div>
                    <div class="unitordertipscontainer">
                        <div class="sessionnumcontainer">
                            <span class="sessionnumfontstyle">{{ matchitenlevel }}</span>
                        </div>
                        <div class="unitordertipcontainer">
                            <!-- <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">时间</span>
                            </el-col> -->
                            <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">场次号</span>
                            </el-col>
                            <el-col :span="8" class="tipsstyle">
                                <span class="tipsfontstyle">青方</span>
                            </el-col>
                            <el-col :span="8" class="tipsstyle">
                                <span class="tipsfontstyle">红方</span>
                            </el-col>
                            <el-col :span="5" class="tipsstyle">
                                <span class="tipsfontstyle">级别</span>
                            </el-col>
                        </div>
                    </div>
                    <div class="unitorderdetailcontainer" v-for="(item) in Item" :key="item.id">
                        <!-- <el-col :span="3">
                            <el-row style="height: 50%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">9:00</span>
                            </el-row>
                        </el-col> -->
                        <el-col :span="3">
                            <el-row style="height: 50%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{ item.field }}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="8">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.groupAName }}</span> </el-col>
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;"><span>{{ item.teamAName }}</span></el-col>
                            </el-row>
                        </el-col>
                        <!-- <el-col :span="2">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>---</span> </el-col>
                            </el-row>
                        </el-col> -->
                        <el-col :span="8">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.groupBName }}</span> </el-col>
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;"><span>{{ item.teamBName }}</span></el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="5">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{ item.comItemName }}</span>
                            </el-row>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ comprehensive_orderlist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '综合成绩表'">
            <div class="modelcomtainer" v-for="(Item, index) in comprehensive_scorelist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">综合秩序表</span>
                    </div>
                    <div class="unitordertipscontainer">
                        <div class="sessionnumcontainer">
                            <span class="sessionnumfontstyle">{{ matchitenlevel }}</span>
                        </div>
                        <div class="unitordertipcontainer">
                            <!-- <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">时间</span>
                            </el-col> -->
                            <el-col :span="2" class="tipsstyle">
                                <span class="tipsfontstyle">场次号</span>
                            </el-col>
                            <el-col :span="7" class="tipsstyle">
                                <span class="tipsfontstyle">青方</span>
                            </el-col>
                            <el-col :span="7" class="tipsstyle">
                                <span class="tipsfontstyle">红方</span>
                            </el-col>
                            <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">比分</span>
                            </el-col>
                            <el-col :span="2" class="tipsstyle">
                                <span class="tipsfontstyle">获胜方式</span>
                            </el-col>
                            <el-col :span="3" class="tipsstyle">
                                <span class="tipsfontstyle">获胜方</span>
                            </el-col>
                        </div>
                    </div>
                    <div class="unitorderdetailcontainer" v-for="(item) in Item" :key="item.id">
                        <!-- <el-col :span="3">
                            <el-row style="height: 50%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">9:00</span>
                            </el-row>
                        </el-col> -->
                        <el-col :span="2">
                            <el-row style="height: 50%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{ item.fieldName }}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="7">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.groupAName }}</span> </el-col>
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.teamAName }}</span>
                                </el-col>
                            </el-row>
                        </el-col>
                        <!-- <el-col :span="2">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>---</span> </el-col>
                            </el-row>
                        </el-col> -->
                        <el-col :span="7">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.groupBName }}</span> </el-col>
                                <el-col :span="12"
                                    style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <span>{{ item.teamBName }}</span>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="3">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                
                                <span style="font-size: small;">{{ item.scoreA }} ： {{ item.scoreB}}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="3">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                
                                <span style="font-size: small;">{{ item.winWay }}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="3">
                            <el-row style="height: 100%;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: 20px;" v-if="item.winner == null"></span>
                                    <span style="font-size: 20px;" v-else-if="item.winner == 'A'">青方</span>
                                    <span style="font-size: 20px;" v-else>红方</span>
                            </el-row>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ comprehensive_scorelist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '排名表'">
            <div class="modelcomtainer" v-for="(Item, index) in ranklist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;margin-bottom: 0px;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">排名表</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">排名</span>
                        </el-col>
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <!-- <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">种子号</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col> -->
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div class="detailmsgcontainer" v-for="(item) in Item" :key="item.id">
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.rankNum }}</span>
                        </el-col>
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.groupName }}</span>
                        </el-col>
                        <!-- <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                        </el-col>
                        <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                        </el-col> -->
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ rankingtablelist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '称重表'">
            <div class="modelcomtainer" v-for="(Item, index) in weightlist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">称重表</span>
                    </div>
                    <div class="weighttipscontainer">
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="2" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col>
                        <el-col :span="4" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">第一次称重</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">第二次称重</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">签名</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">随机称重</span>
                        </el-col>
                        <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">签名</span>
                        </el-col>
                    </div>
                    <div class="signaturecontainer">
                        <div class="althlesignaturecontainer" v-for="(item) in Item" :key="item.id">
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span >{{ item.teamName }}</span>
                            </el-col>
                            <el-col :span="2" style="display: flex;align-items: center;justify-content: center;">
                                <span >{{ item.drawNum }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span >{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span class="wighttipsfontstyle">{{ item.firstWeigh == '0.0' ? '未称重' : item.firstWeigh+'kg'}}</span>
                            </el-col>
                            <el-col :span="3">
                                <div
                                    style="border-bottom: 1px solid black; width: 90%;margin-left: 5%;margin-right: 5%;height: 99%;">
                                </div>
                            </el-col>
                            <el-col :span="3">
                                <div
                                    style="border-bottom: 1px solid black; width: 90%;margin-left: 5%;margin-right: 5%;height: 99%;">
                                </div>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span class="wighttipsfontstyle">{{ item.randomWeigh == '0.0' ? '未称重' : item.randomWeigh+'kg'}}</span>
                            </el-col>
                            <el-col :span="3">
                                <div
                                    style="border-bottom: 1px solid black; width: 90%;margin-left: 5%;margin-right: 5%;height: 99%;">
                                </div>
                            </el-col>
                        </div>
                    </div>
                    <div class="weighttotalcontainer">
                        <el-row style="height: 20%;">
                            <el-col :span="12"
                                style="border: 1px solid black;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">普通称重</span>
                            </el-col>
                            <el-col :span="12"
                                style="border: 1px solid black;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">随机称重</span>
                            </el-col>
                        </el-row>
                        <el-row style="height: 20%;">
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">总数：</span>
                            </el-col>
                            <el-col :span="8"
                                style="border: 1px solid black;display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: small;">{{ weightnum }}</span>
                            </el-col>
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">挑选人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                        </el-row>
                        <el-row style="height: 20%;">
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">通过人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">通过人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                        </el-row>
                        <el-row style="height: 20%;">
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">未通过人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">未通过人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                        </el-row>
                        <el-row style="height: 20%;">
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">未称重人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                            <el-col :span="4" style="border: 1px solid black;display: flex;align-items: center;">
                                <span style="font-size: small;">未称重人数：</span>
                            </el-col>
                            <el-col :span="8" style="border: 1px solid black;"></el-col>
                        </el-row>
                    </div>
                    <div class="officialsignaturecontainer">
                        <el-row style="height: 20%;">
                            <el-col :span="8">
                            </el-col>
                            <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                                <span>名字</span>
                            </el-col>
                            <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                                <span>签名</span>
                            </el-col>
                        </el-row>
                        <el-row style="height: 40%;">
                            <el-col :span="8">
                                <span>裁判：</span>
                            </el-col>
                            <el-col :span="8">
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                            </el-col>
                            <el-col :span="8">
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row style="height: 40%;">
                            <el-col :span="8">
                                <span>IF Delegate:</span>
                            </el-col>
                            <el-col :span="8">
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                            </el-col>
                            <el-col :span="8">
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                                <el-row style="height: 50%;border-bottom: 1px solid black;width: 90%;margin-left: 5%;">
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    <!-- <div class="reportformtipscontainer">
                        <div></div>
                    </div> -->
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ weightlist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '抽签表(空白)'">
            <div class="modelcomtainer" v-for="(Item, index) in blank_drawlist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">{{ modeltype }}</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <!-- <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">种子号</span>
                        </el-col> -->
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col>
                        <!-- <el-col :span="5" class="tipsstyle">
                            <span class="tipsfontstyle">出生日期</span>
                        </el-col> -->
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div class="detailmsgcontainer" v-for="(item) in Item" :key="item.id">
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.groupName }}</span>
                        </el-col>
                        <!-- <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                        </el-col> -->
                        <el-col :span="8"
                            style="display: flex;align-items: center;justify-content: center;border-bottom: 1px solid black;">
                        </el-col>
                        <!-- <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                        </el-col> -->
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ blank_drawlist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-if="modeltype == '抽签表(已抽签)'">
            <div class="modelcomtainer" v-for="(Item, index) in drawlist" :key="Item.id">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/reporttemplateimg/matchimg.jpg" mode="heightFix">
                    </el-col>
                    <!-- <el-col :span="9" class="matchnamemsgcontainer">
                        <el-row class="matchnamecontainer">
                            <span class="matchnamefontstyle">{{ matchname }}</span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle"></span>
                        </el-row>
                        <el-row class="matchdatacontainer">
                            <span class="matchdatafontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    <!-- </el-col> -->
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                        <!-- <el-row class="matchlefttitletwocontainer">
                            <span class="matchlefttitletwofontstyle"></span>
                        </el-row>
                        <el-row class="matchlefttitletherecontainer">
                            <span class="matchlefttitletherefontstyle">{{ matchdata }}</span>
                        </el-row> -->
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchgrouptype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/itemimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">{{ modeltype }}</span>
                    </div>
                    <div class="tipscontainer">
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">NOC</span>
                        </el-col>
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">名字</span>
                        </el-col>
                        <!-- <el-col :span="3" class="tipsstyle">
                            <span class="tipsfontstyle">种子号</span>
                        </el-col> -->
                        <el-col :span="8" class="tipsstyle">
                            <span class="tipsfontstyle">抽签号</span>
                        </el-col>
                        <!-- <el-col :span="5" class="tipsstyle">
                            <span class="tipsfontstyle">出生日期</span>
                        </el-col> -->
                    </div>
                    <!-- <div class="contentdetailcontainer">
                        <div class="detailmsgcontainer" v-for="(item) in userlist" :key="item.id">
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.unit }}</span>
                            </el-col>
                            <el-col :span="6" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.name }}</span>
                            </el-col>
                            <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                            </el-col>
                            <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.BibNumber }}</span>
                            </el-col>
                            <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                                <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                            </el-col>
                        </div>
                    </div> -->
                    <div class="detailmsgcontainer" v-for="(item) in Item" :key="item.id">
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.teamName }}</span>
                        </el-col>
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.groupName }}</span>
                        </el-col>
                        <!-- <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.seed }}</span>
                        </el-col> -->
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.drawNum }}</span>
                        </el-col>
                        <!-- <el-col :span="5" style="display: flex;align-items: center;justify-content: center;">
                            <span style=" font-size: small;font-weight: bold;">{{ item.brithday }}</span>
                        </el-col> -->
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/reporttemplateimg/lowerleftimg.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                        <span>{{ index + 1 }} / {{ drawlist.length }}</span>
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/reporttemplateimg/lowerrightimg.jpg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    created() {
        // // 组件创建时获取查询参数
        // const imageSrc = this.$route.query.imageSrc;
        // // 现在你可以使用 imageSrc，例如设置到组件的数据属性
        // this.imageSrc = decodeURIComponent(imageSrc);
        console.log(this.$route.query);
        if (this.$route.query.type == '个人') {
            this.modeltype = '报名表(个人)'
            this.upgradedata('报名表(个人)')
        } else {
            this.modeltype = '报名表(团队)'
            this.upgradedata('报名表(团队)')
        }
    },
    methods: {
        upgradedata(value) {
            var that = this
            switch (value) {
                case "报名表(个人)":
                    //这里是值对应的处理

                    axios({
                        url: this.$globalurl + 'report/getApplicationReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response);
                        let currentlist = []
                        let a = 0
                        that.person_applylist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.applicationReports.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.applicationReports[i])
                                    that.person_applylist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.applicationReports[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.person_applylist.push(currentlist)
                            }
                        }
                        console.log(that.person_applylist)
                    })


                    break
                case "报名表(团队)":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getApplicationReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response)
                        console.log(response.data.applicationReports);
                        let currentlist = []
                        let a = 0
                        that.team_applylist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.applicationReports.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.applicationReports[i])
                                    that.team_applylist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.applicationReports[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.team_applylist.push(currentlist)
                            }
                        }
                        console.log(that.team_applylist)
                    })
                    break
                case "抽签表(空白)":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getApplicationReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response.data.applicationReports);
                        let currentlist = []
                        let a = 0
                        that.blank_drawlist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.applicationReports.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.applicationReports[i])
                                    that.blank_drawlist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.applicationReports[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.blank_drawlist.push(currentlist)
                            }
                        }
                        console.log(that.blank_drawlist)
                    })
                    break
                case "抽签表(已抽签)":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getApplicationReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response.data.applicationReports);
                        let currentlist = []
                        let a = 0
                        that.drawlist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.applicationReports.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.applicationReports[i])
                                    that.drawlist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.applicationReports[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.drawlist.push(currentlist)
                            }
                        }
                        console.log(that.drawlist)
                    })
                    break
                case "参赛表(个人)":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getApplicationReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response.data.applicationReports);
                        let currentlist = []
                        let a = 0
                        that.person_entrylist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.applicationReports.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.applicationReports[i])
                                    that.person_entrylist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.applicationReports[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.person_entrylist.push(currentlist)
                            }
                        }
                        console.log(that.person_entrylist)
                    })
                    break
                case "参赛表(团队)":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getApplicationReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response.data.applicationReports);
                        let currentlist = []
                        let a = 0
                        that.team_entrylist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.applicationReports.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.applicationReports[i])
                                    that.team_entrylist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.applicationReports[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.team_entrylist.push(currentlist)
                            }
                        }
                        console.log(that.team_entrylist)
                    })
                    break
                // case "单元秩序表":
                //     //这里是值对应的处理
                //     let sessionId
                //     axios({
                //         url: this.$globalurl+'session/getByGameIdAndItemName',
                //         method: 'get',
                //         params: {
                //             comItemName: this.$route.query.itemName,
                //             gameId: this.$route.query.gameId
                //         },
                //         headers: {
                //             'content-type': 'application/json'
                //         }
                //     }).then(function (response) {
                //         console.log(response);
                //         sessionId = response.data.id
                //         console.log(sessionId);
                //     })
                //     axios({
                //         url: this.$globalurl+'report/getSchedule',
                //         method: 'get',
                //         params: {
                //             comItemId: this.$route.query.comItemId,
                //             session
                //         },
                //         headers: {
                //             'content-type': 'application/json'
                //         }
                //     }).then(function (response) {
                //         console.log(response.data.applicationReports);
                //         let currentlist = []
                //         let a = 0
                //         that.team_entrylist = []
                //         that.matchname = response.data.gameName
                //         that.matchitemname = response.data.comItem.name
                //         that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                //         that.matchgrouptype = response.data.comItem.constituencyName
                //         if (response.status == 200) {
                //             for (let i = 0; i < response.data.applicationReports.length; i++) {
                //                 if (a == that.num - 1) {
                //                     currentlist.push(response.data.applicationReports[i])
                //                     that.team_entrylist.push(currentlist)
                //                     currentlist = []
                //                     a = 0
                //                 }
                //                 else {
                //                     currentlist.push(response.data.applicationReports[i])
                //                     a++
                //                 }
                //             }
                //             if (a !== 0) {
                //                 that.team_entrylist.push(currentlist)
                //             }
                //         }
                //         console.log(that.team_entrylist)
                //     })
                //     break
                // case "单元对阵表":
                //     axios({
                //         url: this.$globalurl+'report/getApplicationReport',
                //         method: 'get',
                //         data: {
                //             comItemId: 120
                //         },
                //         headers: {
                //             'content-type': 'application/json'
                //         }
                //     }).then(function (response) {
                //         console.log(response);
                //     })
                //     break
                // case "综合秩序表":
                //     axios({
                //         url: this.$globalurl+'report/getSchedule',
                //         method: 'get',
                //         params: {
                //             comItemId: this.$route.query.comItemId
                //         },
                //         headers: {
                //             'content-type': 'application/json'
                //         }
                //     }).then(function (response) {
                //         console.log(response.data.scheduleList);
                //         let currentlist = []
                //         let a = 0
                //         that.comprehensive_orderlist = []
                //         if (response.status == 200) {
                //             for (let i = 0; i < response.data.scheduleList.length; i++) {
                //                 if (a == that.num - 1) {
                //                     currentlist.push(response.data.scheduleList[i])
                //                     that.comprehensive_orderlist.push(currentlist)
                //                     currentlist = []
                //                     a = 0
                //                 }
                //                 else {
                //                     currentlist.push(response.data.scheduleList[i])
                //                     a++
                //                 }
                //             }
                //             if (a !== 0) {
                //                 that.comprehensive_orderlist.push(currentlist)
                //             }
                //         }
                //         console.log(that.comprehensive_orderlist)
                //     })
                //     break
                // case "综合对阵表":
                //     axios({
                //         url: this.$globalurl+'report/getApplicationReport',
                //         method: 'get',
                //         data: {
                //             comItemId: 120
                //         },
                //         headers: {
                //             'content-type': 'application/json'
                //         }
                //     }).then(function (response) {
                //         console.log(response);
                //     })
                //     break
                case "称重表":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getWeighReport',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response.data);
                        let currentlist = []
                        let a = 0
                        that.weightlist = []
                        that.weightnum = response.data.length
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data[i])
                                    that.weightlist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.weightlist.push(currentlist)
                            }
                        }
                        console.log(that.weightlist)
                    })
                    break
                case "综合成绩表":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getGrade',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response);
                        let currentlist = []
                        let a = 0
                        that.comprehensive_scorelist = []
                        that.weightnum = response.data.gradeList.length
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.gradeList.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.gradeList[i])
                                    that.comprehensive_scorelist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.gradeList[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.comprehensive_scorelist.push(currentlist)
                            }
                        }
                        console.log(that.comprehensive_scorelist)
                    })
                    break
                case "综合秩序表":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getSchedule',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response);
                        console.log(response.data);
                        let currentlist = []
                        let a = 0
                        that.comprehensive_orderlist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.scheduleList.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.scheduleList[i])
                                    that.comprehensive_orderlist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.scheduleList[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.comprehensive_orderlist.push(currentlist)
                            }
                        }
                        console.log(that.comprehensive_orderlist)
                    })
                    break
                    case "排名表":
                    //这里是值对应的处理
                    axios({
                        url: this.$globalurl + 'report/getRank',
                        method: 'get',
                        params: {
                            comItemId: this.$route.query.comItemId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function (response) {
                        console.log(response);
                        console.log(response.data);
                        let currentlist = []
                        let a = 0
                        that.ranklist = []
                        that.matchname = response.data.gameName
                        that.matchitemname = response.data.comItem.name
                        that.matchitenlevel = response.data.comItem.gender + '-' + response.data.comItem.level
                        that.matchgrouptype = response.data.comItem.constituencyName
                        if (response.status == 200) {
                            for (let i = 0; i < response.data.rankList.length; i++) {
                                if (a == that.num - 1) {
                                    currentlist.push(response.data.rankList[i])
                                    that.ranklist.push(currentlist)
                                    currentlist = []
                                    a = 0
                                }
                                else {
                                    currentlist.push(response.data.rankList[i])
                                    a++
                                }
                            }
                            if (a !== 0) {
                                that.ranklist.push(currentlist)
                            }
                        }
                        console.log(that.ranklist)
                    })
                    break
                default:
                    //这里是没有找到对应的值处理
                    break
            }
        },
        numChange(e) {
            this.upgradedata(this.modeltype)
            console.log(e);
        },
        SelectChange(e) {
            if (e.target.value == '报名表') {
                if (this.$route.query.type == '个人') {
                    this.modeltype = '报名表(个人)'
                    this.upgradedata('报名表(个人)')
                } else {
                    this.modeltype = '报名表(团队)'
                    this.upgradedata('报名表(团队)')
                }
            }
            else if (e.target.value == '参赛表') {
                if (this.$route.query.type == '个人') {
                    this.modeltype = '参赛表(个人)'
                    this.upgradedata('参赛表(个人)')
                } else {
                    this.modeltype = '参赛表(团队)'
                    this.upgradedata('参赛表(团队)')
                }
            }
            else {
                this.modeltype = e.target.value
                this.upgradedata(e.target.value)
            }

        },
        test() {
            this.scaleImage()
        },
        // backPage() {
        //     console.log(1)
        //     this.$router.go(-1);
        // },
    },
    data() {
        return {
            num: 15,
            reportype: '报名表',
            numlist: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 },
                { label: 5, value: 5 },
                { label: 6, value: 6 },
                { label: 7, value: 7 },
                { label: 8, value: 8 },
                { label: 9, value: 9 },
                { label: 10, value: 10 },
                { label: 11, value: 11 },
                { label: 12, value: 12 },
                { label: 13, value: 13 },
                { label: 14, value: 14 },
                { label: 15, value: 15 },
                { label: 16, value: 16 },
                { label: 17, value: 17 },
                { label: 18, value: 18 },
                { label: 19, value: 19 },
                { label: 20, value: 20 },
            ],
            options: [
                { label: '报名表', value: '报名表' },
                { label: '抽签表(空白)', value: '抽签表(空白)' },
                { label: '抽签表(已抽签)', value: '抽签表(已抽签)' },
                { label: '参赛表', value: '参赛表' },
                // { label: '单元秩序表', value: '单元秩序表' },
                // { label: '单元对阵表', value: '单元对阵表' },
                // { label: '综合对阵表', value: '综合对阵表' },
                { label: '称重表', value: '称重表' },
                { label: '综合秩序表', value: '综合秩序表' },
                { label: '综合成绩表', value: '综合成绩表' },
                { label: '排名表', value: '排名表' },
            ],
            rankingtablelist:[
                [
                {
                    noc:'炫舞代表队',
                    name:'张三',
                    rank:1,
                },
                {
                    noc:'炫舞代表队',
                    name:'李四',
                    rank:2,
                },
                {
                    noc:'炫舞代表队',
                    name:'王五',
                    rank:3,
                },
                {
                    noc:'炫舞代表队',
                    name:'李四',
                    rank:4,
                },
                {
                    noc:'炫舞代表队',
                    name:'张三',
                    rank:5,
                },
                {
                    noc:'炫舞代表队',
                    name:'王五',
                    rank:6,
                }
                ]
            ],
            ranklist:[],
            comprehensive_scorelist: [],
            comprehensive_orderlist: [],
            person_entrylist: [],
            team_entrylist: [],
            blank_drawlist: [],
            drawlist: [],
            person_applylist: [],
            team_applylist: [],
            weightlist: [],
            unitorderlist: [],//单元秩序表数据列表
            modeltype: '',
            userlist: [],
            title: '报名表',
            matchitenlevel: '',
            matchgrouptype: '',
            matchitemname: '',
            matchdata: '',
            matchname: '',
            // TemplatePrintingActive: false,
            // modelimageSrc: '',
            imageSrc: '',
            weightnum: '',
        }
    },

    // mounted() {
    //     // 组件挂载后，等图片加载完成再缩放
    //     this.scaleImage();
    // },
};
</script>

<style scoped>
/* el-divider 修改高度&虚线效果 */
.signaturecontainer {
    height: 60%;
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: column;
}

.althlesignaturecontainer {
    height: 5%;
    display: flex;
}

.weighttotalcontainer {
    border: 1px solid black;
    height: 15%;
    width: 98%;
    margin-left: 1%;
}

.officialsignaturecontainer {
    height: 15%;
    width: 98%;
    margin-left: 1%;
}

/* .reportformtipscontainer {
    height: 5%;
    width: 98%;
    margin-left: 1%;
} */

.tipsfontstyle {
    font-weight: bold;
}

.tipsstyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-divider--horizontal {
    margin-top: 8px;
    margin-bottom: 2px;
    background: 0 0;
    border-top: 1px solid black;
}

/* .matchimgcontainer {
    max-width: 100%;
    max-height: 100%;
} */

.toptitlecontainer {
    height: 8%;
    width: 100%;
}

.matchrighttitletwofontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchrighttitletherefontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchrighttitletwocontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
}

.matchrighttitletherecontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.matchrighttitleonefontstyle {
    font-size: large;
    font-weight: bold;
}

.matchrighttitleonecontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
}

.matchlefttitletwofontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchlefttitletherefontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchlefttitletwocontainer {
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-left: 5%;
}

.matchlefttitletherecontainer {
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-left: 5%;
    padding-bottom: 5%;
}

.matchlefttitleonefontstyle {
    font-size: larger;
    font-weight: bold;
}

.matchlefttitleonecontainer {
    text-align: center;
    height: 26%;
    padding-top: 5%;
    padding-left: 5%;
}

.modelcomtainer {
    /* width: 612pt;
    height: 784pt; */
    width: 210mm;
    height: 297mm;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid #ddd; */
    background-color: white;
}

.image-viewer {
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgb(246, 246, 246);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 根据需要添加内边距 */

}

.image-viewer img {
    max-width: 100%;
    max-height: 100%;
}

.contentcontainer {
    height: 85%;
    width: 100%;
}

.titlecontainer {
    /* padding: 2%; */
    height: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weighttipscontainer {
    width: 98%;
    margin-left: 1%;
    height: 5%;
    display: flex;
    border: 1px solid black;
}

.titlefontstyle {
    font-size: large;
    font-weight: bold;
}

.tipscontainer {
    display: flex;
    height: 5%;
    width: 98%;
    margin-left: 1%;
    border: 1px solid black;
}

.unitordertipscontainer {
    display: flex;
    flex-direction: column;
    height: 8%;
    margin-left: 1%;
    width: 98%;
    border: 1px solid black;
}

.sessionnumcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    /* width: 100%; */
    height: 39%;
}

.sessionnumfontstyle {
    font-size: large;
    font-weight: bold;
}

.unitordertipcontainer {
    display: flex;
    height: 60%;
}

.unitorderdetailcontainer {
    /* height: 5%; */
    /* padding-top: 5px; */
    margin-left: 1%;
    width: 98%;
    margin-top: 2%;
    margin-bottom: 3%;
    display: flex;
}

.contentdetailcontainer {
    height: 90%;
    width: 98%;
    margin-left: 1%;
}

.detailmsgcontainer {
    display: flex;
    width: 98%;
    margin-left: 1%;
    margin-top: 2%;
    margin-bottom: 3%;
}

.btncontainer {
    position: fixed;
    /* 固定定位 */
    top: 20px;
    /* 距离顶部20像素 */
    right: 20px;
    /* 距离右侧20像素 */
    z-index: 1000;
    /* 确保按钮在页面其他内容的上方 */
}

.el-row {
    margin-bottom: 0px;
}

.el-col {
    margin-bottom: 0px;
}

/* 定义打印样式，隐藏按钮 */
/* @media print {
    .btncontainer {
        display: none;
    }
} */
</style>