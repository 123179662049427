<template>
  <div class="main-container">
    <!-- 容器用于应用Flexbox布局 -->
    <!-- <button v-print="'#print-content'">打印</button>
    <button @click="convertToImage">生成模板</button> -->
    <el-button
      v-print="'#print-content'"
      type="success"
      style="margin-left: 20px"
      >打印</el-button
    >
    <el-button @click="convertToImage" type="primary">生成模板</el-button>
    <!-- 图片弹窗组件 -->
    <!-- <ImageViewer v-if="isImageViewerActive" :image-src="imageSrc" @close="closeImageViewer" /> -->
    <!-- &nbsp;<el-button type="primary" @click="test()">保&nbsp;存&nbsp;<el-icon>
        <Check />
      </el-icon></el-button>&nbsp;&nbsp;&nbsp;
    <el-input @change="changeData" v-model="selectedUsername" style="width: 240px" placeholder="请输入修改的值" /> -->

    <div class="flex-container">
      <!-- 主节点 -->
      <div class="flex-container" ref="printArea" id="print-content">
        <div v-for="n in levels" :key="'layer-' + n">
          <!-- 每层生成相应数量的MatchDiagramPage组件 -->
          <MatchDiagramPage
            v-for="i in 2 ** (levels - n)"
            :key="'match-' + i + '-' + n"
            :height="calculateHeight(levels - n + 1)"
            :usernameBlue="
              this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2 - 1]
            "
            :levels="levels"
            :nowLevel="n"
            :usernameRed="
              this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2]
            "
            :session="this.dataUserList[ (2 ** (levels - n )+(i - 2) )].fieldName+this.dataUserList[ (2 ** (levels - n )+(i - 2) )].fno"
            :keyIndex="dataUserList[2 ** (levels - n) - 1 + (i - 1)].id"
            @username-clicked="handleUsernameClick($event)"
            @keyIndex-clicked="handleKeyIndexClick($event)"
            :style="{
              height: calculateHeight(levels - n + 1) + 'px',
            }"
            :myArray="myArray"
          >
          </MatchDiagramPage>
        </div>
        <div>
          <svg width="100" height="38">
            <rect
              x="0"
              y="5"
              width="100"
              height="30"
              style="
                fill: rgb(255, 255, 255);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <text
              x="50%"
              y="50%"
              fill="black"
              font-family="Arial"
              font-size="10"
              text-anchor="middle"
              alignment-baseline="central"
              v-text="this.winName"
            ></text>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="diaArrange" style=" width: 500px">
    <p1 style="font-weight:bold">{{this.setForm.field}} {{ this.setForm.number }}</p1>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="设置" name="设置">
        <el-form v-model="setForm" label-width="70px">
          <el-form-item label="场地" style="margin-bottom: 10px">
            <el-select
              v-model="setForm.field"
              placeholder="选择场地"
              style="width: 120px"
            >
              <el-option v-for="location in locationList" :key="location.id" :label="location.name" :value="location.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="场号" style="margin-bottom: 10px">
            <el-input v-model="setForm.number" style="width: 120px" />
          </el-form-item>
          <el-form-item label="session" style="margin-bottom: 10px">
            <el-select
              v-model="setForm.session"
              placeholder="session"
              style="width: 120px"
            >
              <el-option v-for="session in sessionList" :key="session.id" :value="session.id" :label="session.sessionNum"></el-option>
            </el-select>
            <el-time-picker style="margin-left: 10px; width: 120px"  v-model="setForm.sessionTime" placeholder="选择时间" format="HH:mm" value-format="YYYY-MM-DD HH:mm" />
          </el-form-item>
          <el-form-item label="比赛状态" >
          <el-radio-group v-model="setForm.status">
            <el-radio value="0" @click="this.showPage='none'">未比赛</el-radio>
            <el-radio value="1"  @click="this.showPage='none'">正在比赛</el-radio>
            <el-radio value="2" @click="this.showPage='block'">比赛结束</el-radio>
          </el-radio-group>
          </el-form-item>
          <div :style="{ display : this.showPage }">
            <el-form-item label="红方分数" style="margin-bottom: 10px">
            <el-input v-model="setForm.A_score" style="width: 100px" />
          </el-form-item>
          <el-form-item label="青方分数" style="margin-bottom: 10px">
            <el-input v-model="setForm.B_score" style="width: 100px" />
          </el-form-item>
          <el-form-item label="胜利方" style="margin-bottom: 10px">
            <el-radio-group v-model="setForm.winner">
            <el-radio value="B">红方</el-radio>
            <el-radio value="A">青方</el-radio>
          </el-radio-group>
          </el-form-item>
          <el-form-item label="胜利方式" style="margin-bottom: 10px">
            <el-select
              v-model="setForm.winWay"
              placeholder="胜利方式"
              style="width: 100px"
            >
              <el-option label="PTF" value="PTF" />
              <el-option label="DSQ" value="DSQ" />
              <el-option label="PUN" value="PUN" />
              <el-option label="PTG" value="PTG" />
              <el-option label="RSC" value="RSC" />
              <el-option label="GDP" value="GDP" />
              <el-option label="SUP" value="SUP" />
              <el-option label="WDR" value="WDR" />
              <el-option label="DQB" value="DQB" />
            </el-select>
          </el-form-item>
          </div>
          
        <el-form-item  style="margin-left: 250px; margin-top: 50px;">
            <el-button type="primary" @click="onSubmit()">确定</el-button>
            <el-button @click="this.diaArrange =false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
   
    </el-tabs>
  </el-dialog>
</template>

<script>
import axios from "axios";
import MatchDiagramPage from "./MatchDiagramPage.vue";
import html2canvas from "html2canvas";
// import ImageViewer from '../components/ImageViewer.vue'; // 引入弹窗组件
export default {
  name: "VerticalButtonHierarchy",
  created() {
    this.arrange();
    this.getAllLocation()
    this.getAllSession()
  },
  data() {
    return {
      // isImageViewerActive: false,
      // imageSrc: '',
      levels: 0,
      height: 0,
      dataUserList: [],
      showDataList: [],
      selectedUsername: "",
      updateDatakeyIndex: "",
      diaArrange: false,
      height_up_List: [],
      height_down_list: [],
      sessionList:[],
      countWheel: 0,
      height_list: [],
      activeName: "设置",
      locationList:[],
      setForm: {
        field: '',
        number: "",
        session: "",
        sessionTime:'',
        A_score: "",
        B_score: "",
        winWay: "PTF",
        arrangeId: "",
        winner: "",
        status:'',
      }, 
      index:'',
      showPage:'none',
      winName:''
    };
  },
  components: {
    MatchDiagramPage,
    // ImageViewer
  },
  methods: {
    convertToImage() {
      const printArea = this.$refs.printArea;
      html2canvas(printArea)
        .then((canvas) => {
          // 将 canvas 转换为图片数据 URL
          const imgData = canvas.toDataURL("image/png");
          // console.log(imgData);
          // 这里可以进一步处理或显示图片，例如：
          // 1. 将图片数据 URL 用于下载
          // 2. 显示在页面上的某个元素中
          // 3. 进行图片分割等操作
          // this.imageSrc = imgData;
          // this.isImageViewerActive = true; // 显示弹窗
          const encodedImgData = encodeURIComponent(imgData);
          // 使用查询参数对象传递 imageSrc
          this.$router.push({
            path: "/toTemplateviewPage",
            query: {
              imageSrc: encodedImgData,
            },
          });
        })
        .catch((error) => {
          console.error("html2canvas 失败:", error);
        });
    },
    // 编排
    arrange() {
      var that = this;
      axios({
        url: this.$globalurl + "arrange/getByComItemId",
        method: "post",
        data: this.$route.query.comItemId,
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        console.log(response.data);
        var flag = Math.floor(response.data.主赛.length / 2);
        that.showDataList[0] = 0;
        var index = 1;
        that.dataUserList = response.data.主赛;
        for (var i = 0; i < response.data.主赛.length; i++) {
          if (
            i >= flag &&
            response.data.主赛[i].drawANum == null &&
            response.data.主赛[i].drawBNum == null
          ) {
            that.showDataList[index] = "none2";
            index = index + 1;
            that.showDataList[index] = "none2";
            index = index + 1;
            that.countWheel = that.countWheel + 1;
          } else {
            that.showDataList[index] =
              response.data.主赛[i].drawANum + "-" + response.data.主赛[i].groupAName;
            index = index + 1;
            that.showDataList[index] =
              response.data.主赛[i].drawBNum + "-" + response.data.主赛[i].groupBName;
            index = index + 1;
          }
        }
        var levels = 0;
        var len = response.data.主赛.length;
        while (len > 0) {
          len = Math.floor(len / 2);
          levels++;
        }
        that.levels = levels;
        that.height = 2 ** that.levels * 40;

        // 判断冠军
        if (that.dataUserList[0].status==="比赛已结束") {
            
            if (that.dataUserList[0].winner==="A") {
              that.winName=that.dataUserList[0].groupAName
            }else{
              that.winName=that.dataUserList[0].groupBName
            }
          }else{
            that.winName=''
          }
      });
    },
    // 根据层级计算每个MatchDiagramPage组件的高度
    calculateHeight(level) {
      // 计算公式可以根据具体需求进行调整
      // 这里只是简单地返回一个值
      // if(this.height / (2 ** (level-1))<80){
      //   return 100
      // }
      return (this.height / 2 ** (level - 1)).toFixed(0);
    },
    SubmitSave() {
      // 向后后台发送数据
      console.log(this.showDataList[0]);
      this.showDataList[1] = "张三";
      this.showDataList[2] = "里斯";
    },
    handleUsernameClick(username) {
      this.selectedUsername = username;
    },
    handleKeyIndexClick(keyIndex) {
      this.updateDatakeyIndex = keyIndex;
      this.diaArrange = true;
      var that = this
      axios({
        url: this.$globalurl + "arrange/getById",
        method: "get",
        params: {arrangeId:this.updateDatakeyIndex},
        headers: {
          "content-type": "application/json",
        },
      }).then(function(response){
        console.log(response.data);
        that.setForm.number = response.data.fno
        for(var i=0 ;i<that.locationList.length;i++){
          if(that.locationList[i].id==response.data.fieldId){
            that.setForm.field = that.locationList[i].name
          }
        }
        // that.setForm.status = response.data.status
        if (response.data.status==2) {
          that.showPage='block'
        }else{
                that.showPage='none'
        }
        that.setForm.status = response.data.status+''
        that.setForm.A_score = response.data.scoreA
        that.setForm.B_score = response.data.scoreB
        that.setForm.winWay = response.data.winWay
        that.setForm.winWay = response.data.winWay
        that.setForm.winner = response.data.winner
        that.setForm.sessionTime = response.data.time
      })
    },
    changeData() {
      this.showDataList[parseInt(this.updateDatakeyIndex)] =
        this.selectedUsername;
    },
    getAllLocation() {
        var that = this
      axios({
        url: this.$globalurl + "field/getByGameAndItemName",
        method: "get",
        params: {comItemName: localStorage.getItem("itemName"),gameId:localStorage.getItem("comId")},
        headers: {
          "content-type": "application/json",
        },
      }).then(function(response){
        that.locationList = response.data
        that.setForm.field = that.locationList[0].name
      });
    },
    onSubmit(){
      var that =this
      this.diaArrange = false

      for(var i=0 ;i<this.locationList.length;i++){
          if(this.locationList[i].name ==this.setForm.field){
            this.index = this.locationList[i].id
          }
      }
      console.log({id:this.updateDatakeyIndex,fno:this.setForm.number,scoreA:this.setForm.A_score,fieldId:this.index,
              scoreB:this.setForm.B_score,status:this.setForm.status,
             time:this.setForm.sessionTime,winWay:this.setForm.winWay,winner:this.setForm.winner ,sessionId:this.setForm.session});
      axios({
        url: this.$globalurl+'race/updateArrange',
            method:"post",
            data:{id:this.updateDatakeyIndex,fno:this.setForm.number,scoreA:this.setForm.A_score,fieldId:this.index,
              scoreB:this.setForm.B_score,status:this.setForm.status,
             time:this.setForm.sessionTime,winWay:this.setForm.winWay,winner:this.setForm.winner ,sessionId:this.setForm.session},
            headers:{
                'content-type':'application/json'
            },
      }).then(function(response){
        console.log(response.data);
        if (response.data ==true) {
            that.$message({
              type:'success',
              message:'编排成功'
            })
            that.arrange()
        
        }
      }).catch(error=>{
        if(error.response.status==691){
            that.$message({
              type:'error',
              message:'影响后续编排'
            })
        }
      })
    },
    getAllSession(){
      var that = this
      axios({
        url: this.$globalurl + "session/getByGameIdAndItemName",
        method: "get",
        params: {comItemName: localStorage.getItem("itemName"),gameId:localStorage.getItem("comId")},
        headers: {
          "content-type": "application/json",
        },
      }).then(function(response){
        that.sessionList = response.data
      });
    },
  },
};
</script>

<style scoped>
html,
body,
div {
  margin: 0;
  padding: 0;
}

.flex-container {
  display: flex;
  /* 启用Flexbox布局 */
  align-items: center;
  height: 100%;
}

.three-quarters-align {
  height: 25%;
  /* 容器高度为视口高度的3/4 */
}

.svg {
  margin: 0;
  padding: 0;
}
</style>