<template>
  <!-- 场地管理 -->
  <el-card class="box-card">
    <el-button link type="primary" @click="diaAddlocation = true"
      ><el-icon style="height: 30px; width: 25px">
        <Plus /> </el-icon
      >添加场地</el-button
    >
    <el-button link type="primary" @click="diaImportKPNP=true">导入KPNP</el-button>
    <!-- 显示比赛 -->
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in LocationList" :key="index">
        <el-card class="match-card">
          <div class="match-info">
            <h3>场地名称-{{ item.name }}</h3>
            <p>
              <el-button link type="success">查看</el-button
              ><el-button link type="danger" @click="deleteLocation(item.id)"
                >删除</el-button
              >
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
  <el-dialog
    v-model="diaAddlocation"
    title="添加场地"
    width="700"
    :before-close="handleClose"
  >
    <el-form-item label="场地名称">
      <el-input style="width: 200px" v-model="this.locationName" clearable />
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaAddlocation = false">取消</el-button>
        <el-button type="primary" @click="handleAdd()"> 确定添加 </el-button>
      </div>
    </template>
  </el-dialog>
  <!-- 单元 -->
  <el-card class="box-card">
    <el-button link type="primary" @click="diaSession = true"
      ><el-icon style="height: 30px; width: 25px">
        <Plus /> </el-icon
      >添加单元</el-button
    >
    <!-- 显示比赛 -->
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in sessionList" :key="index">
        <el-card class="match-card">
          <div class="match-info">
            <h3>单元名称-{{ item.sessionNum }}</h3>
            <h3>开始时间{{ item.beginTime }}</h3>
            <h3>结束时间{{ item.endTime }}</h3>
            <p>
              <el-button link type="success">查看</el-button
              ><el-button link type="danger" @click="deleteSession(item.id)"
                >删除</el-button
              >
              <el-button link type="primary" @click="toReportPrint(item)"
                >打印单元秩序单</el-button
              >
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
  <el-dialog
    v-model="diaSession"
    title="添加单元"
    width="700"
    :before-close="handleClose"
  >
    <el-form-item label="单元名称">
      <el-input style="width: 200px" v-model="this.sessionName" clearable />
    </el-form-item>
    <el-form-item label="开始时间">
      <el-date-picker
        v-model="this.sessionStartTime"
        type="datetime"
        placeholder="开始时间"
        format="YYYY/MM/DD hh:mm"
        value-format="YYYY-MM-DD h:m"
      />
    </el-form-item>
    <el-form-item label="结束时间">
      <el-date-picker
        v-model="this.sessionEndTime"
        type="datetime"
        placeholder="结束时间"
        format="YYYY/MM/DD hh:mm"
        value-format="YYYY-MM-DD h:m"
      />
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaSession = false">取消</el-button>
        <el-button type="primary" @click="addSession()"> 确定添加 </el-button>
        
      </div>
    </template>
  </el-dialog>
  <!-- 组别 -->
  <el-card class="box-card">
    <el-button link type="primary" @click="diaGroup = true"
      ><el-icon style="height: 30px; width: 25px">
        <Plus /> </el-icon
      >添加组别</el-button
    >
    <!-- 显示比赛 -->
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in gropuList" :key="index">
        <el-card class="match-card">
          <div class="match-info">
            <h3>组别名称-{{ item.name }}</h3>
            <p>
              <el-button link type="success">查看</el-button
              ><el-button link type="danger" @click="deleteGroup(item.id)"
                >删除</el-button
              >
              
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
  <el-dialog
    v-model="diaGroup"
    title="添加组别"
    width="700"
    :before-close="handleClose"
  >
    <el-form-item label="组别名称">
      <el-input style="width: 200px" v-model="this.groupName" clearable />
    </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaGroup = false">取消</el-button>
        <el-button type="primary" @click="addGroup()"> 确定添加 </el-button>
      </div>
    </template>
  </el-dialog>
<!-- 导入KPNP信息 -->
  <el-dialog v-model="diaImportKPNP" style="width: 400px;">
    <el-form-item label="mdb数据库url" label-width="120">
      <el-input style="width: 200px;" v-model="mdbUrl"></el-input>
    </el-form-item>
    <el-form-item label="场地" style="margin-bottom: 10px" label-width="120">
            <el-select
              v-model="selecLocationId"
              placeholder="选择场地"
              style="width: 200px"
            >
              <el-option v-for="location in LocationList" :key="location.id" :label="location.name" :value="location.id" />
            </el-select>
          </el-form-item>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaImportKPNP = false">取消</el-button>
        <el-button type="primary" @click="importKPNP()"> 确定导入 </el-button>
      </div>
    </template>
</el-dialog>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      diaAddlocation: false,
      locationName: "",
      sessionName: "",
      groupName: "",
      LocationList: [],
      sessionList: [],
      gropuList: [],
      diaGroup: false,
      diaSession: false,
      diaImportKPNP:false,
      sessionStartTime: "",
      sessionEndTime: "",
      mdbUrl:'',
      selecLocationId:''
    };
  },

  created() {
    this.getAllLocation();
    this.getAllGropu();
    this.getAllSession();
  },
  methods: {
    importKPNP(){
        console.log(this.selecLocationId);
        console.log(this.mdbUrl);
        this.diaImportKPNP=false
    },
    toReportPrint(item) {
      console.log(item);
      console.log(item.gameId);
      console.log(item.id);
      let routeUrl = this.$router.resolve({
        path: "/ToUnitordertablePage",
        query: {
          gameId: item.gameId,
          sessionId: item.id,
          sessionName: item.sessionNum,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    addSession() {
      var that = this;
      axios({
        url: this.$globalurl + "session/add",
        method: "post",
        data: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
          sessionNum: this.sessionName,
          beginTime: this.sessionStartTime,
          endTime: this.sessionEndTime,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data == true) {
          that.$message({
            type: "success",
            message: "添加成功",
          });
          that.getAllSession();
        }
      });
      this.diaSession = false;
    },
    // 获取所有单元
    getAllSession() {
      var that = this;
      axios({
        url: this.$globalurl + "session/getByGameIdAndItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.sessionList = response.data;
      });
    },
    // 获取所有场地
    getAllLocation() {
      var that = this;
      axios({
        url: this.$globalurl + "field/getByGameAndItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.LocationList = response.data;
      });
    },
    // 获取所有组别
    getAllGropu() {
      var that = this;
      axios({
        url: this.$globalurl + "constituency/getByGameIdAndItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.gropuList = response.data;
      });
    },
    // 添加组别
    addGroup() {
      var that = this;
      console.log({
        comItemName: localStorage.getItem("itemName"),
        gameId: localStorage.getItem("comId"),
        name: this.locationName,
      });
      axios({
        url: this.$globalurl + "constituency/add",
        method: "post",
        data: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
          name: this.groupName,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data == true) {
          that.$message({
            type: "success",
            message: "添加成功",
          });
          that.getAllGropu();
        }
      });
      this.diaGroup = false;
    },
    // 添加场地
    handleAdd() {
      var that = this;
      axios({
        url: this.$globalurl + "field/add",
        method: "post",
        data: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
          name: this.locationName,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data == true) {
          that.$message({
            type: "success",
            message: "添加成功",
          });
          that.getAllLocation();
        }
      });
      this.diaAddlocation = false;
    },
    deleteLocation(id) {
      var that = this;
      axios({
        url: this.$globalurl + "field/delete",
        method: "get",
        params: { id: id },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data == true) {
          that.$message({
            type: "success",
            message: "删除成功",
          });
          that.getAllLocation();
        }
      });
    },
    deleteSession(id) {
      var that = this;
      axios({
        url: this.$globalurl + "session/delete",
        method: "get",
        params: { id: id },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data == true) {
          that.$message({
            type: "success",
            message: "删除成功",
          });
          that.getAllSession();
        }
      });
    },
    deleteGroup(id) {
      var that = this;
      axios({
        url: this.$globalurl + "constituency/delete",
        method: "get",
        params: { id: id },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data == true) {
          that.$message({
            type: "success",
            message: "删除成功",
          });
          that.getAllGropu();
        }
      });
    },
  },
};
</script>

<style></style>